import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';


function preventDefault(event) {
  event.preventDefault();
}

const style = theme => ({
  depositContext: {
    flex: 1,
  },
});

class Deposits extends React.Component {
  constructor(props) {
    super(props);
    debugger;
  }

  render() {

  const {classes} = this.props;
  debugger;
  return (
    <div>
    
    <Typography component="h2" variant="h6" color="primary" gutterBottom>Average Time spent</Typography>
      <Typography component="p" variant="h4">
        {this.props.boardData.duration}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
      {this.props.boardData.exactDate}
      </Typography>
      
      </div>
    
  );
}

}
export default withStyles(style)(Deposits);