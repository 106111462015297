let API_URL;
let MET_RETAIL_API_URL,MET_RETAIL_ADMIN_API_URL;
let MS365_API_URL;
const API_CONTEXT_ROOT = 'flow-api';

const host = window && window.location && window.location.hostname;

if(host === 'localhost'){
    API_URL = 'http://localhost:9080/';
    MS365_API_URL = API_URL;
    MET_RETAIL_API_URL = 'https://retail-tst.metropolitan.co.za/auth/login';
    MET_RETAIL_ADMIN_API_URL = 'https://retail-tst.metropolitan.co.za/auth/super/admin';
}else if (host === 'www.flow.metgetup.co.za'){
    API_URL = 'https://api-internal.metropolitan.co.za/';
    MS365_API_URL = API_URL;
    MET_RETAIL_API_URL = 'https://retail.metropolitan.co.za/auth/login';
    MET_RETAIL_ADMIN_API_URL = 'https://retail.metropolitan.co.za/auth/super/admin';
    console.log = function() {}
}else {
    API_URL = 'https://api-dev.getup.metropolitan.co.za/';
    MS365_API_URL = API_URL;
    MET_RETAIL_API_URL = 'https://retail-tst.metropolitan.co.za/auth/login';
    MET_RETAIL_ADMIN_API_URL = 'https://retail-tst.metropolitan.co.za/auth/super/admin';
    // console.log = function() {}
}


console.log('HOST URL ---------- ' + host);
console.log('API URL ---------- ' + API_URL);
console.log('MET RETAIL API URL ---------- ' + MET_RETAIL_API_URL);

export const API_CONFIG = API_URL.concat(API_CONTEXT_ROOT);
export const MS_365_API_CONFIG = MS365_API_URL.concat(API_CONTEXT_ROOT);
export const MET_RETAIL_API_CONFIG = MET_RETAIL_API_URL;
export const MET_RETAIL_ADMIN_API_CONFIG = MET_RETAIL_ADMIN_API_URL;
