import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from '@material-ui/core/Button';
import List from "@material-ui/core/List";
import { Badge/*, Box*/ } from "@material-ui/core";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SpeedIcon from "@material-ui/icons/Speed";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import BuildIcon from '@material-ui/icons/Build';
import PlaceIcon from '@material-ui/icons/Place';
import PowerBiService from "../shared/PowerBiService";
import boardsService from "../shared/boardsService";
//import MailIcon from '@material-ui/icons/Mail';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "../avatar/Avatars";
import "./style.css";
import logo from "./../../images/flow_logo.png";
import appbarLogo from "./../../images/appBar_logo.png"
import Boards from "../boards/worksiteboards/boards";
import { Route, NavLink, HashRouter, Switch } from "react-router-dom";
import { basicNavigate, basicBoardsNavigate } from "./constantNavigate";
import {
    Home,
    Business,
    Extension
} from "@material-ui/icons";
import TodayIcon from "@material-ui/icons/Today";
import HomeLayoutHub from "./HomeLayoutHub";
import InboxLayoutHub from "./InboxLayoutHub";
import ToDoCalendarLayoutHub from "./ToDoCalendarLayoutHub";
import DashboardLayoutHub from "./DashboardLayoutHub";
// import GetAppIcon from "@material-ui/icons/GetApp";
import MenuItem from '@material-ui/core/MenuItem';
import * as Cookies from "js-cookie";
import Typography from "@material-ui/core/Typography";
import Supportboards from "./../boards/supportboard/Supportboards";
import AuditFlowDashboard from "./auditFlowDashboard/AuditFlowDashboard";
import GetUpSolution from "./GetUpSolution";
import ToolsLayout from "./ToolsLayout";
import SlackChatUI from "./SlackChatUI";
import Menu from "@material-ui/core/Menu";
import NotificationsIcon from '@material-ui/icons/Notifications';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
//import {withRouter} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import axios from "axios";


import SetGoals from "./SetGoals";
import Tooltip from "@material-ui/core/Tooltip";
import context from "react-router/modules/RouterContext";
import {API_CONFIG} from "../../config/api-config";
import avatarService from "../shared/AvatarService";
import AssessmentIcon from '@material-ui/icons/Assessment';
import IdleTimerContainer from './IdleTimerContainer';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import Collapse from '@material-ui/core/Collapse';



const drawerWidth = 230;

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginLeft: 5
    },
    hide: {
        display: "none"
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        marginRight: "10px"
    },
    drawerOpen: {
        background: "#673ab7",
        color: "#ffffff",
        width: drawerWidth,
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        background: "#673ab7",
        color: "#673ab7;",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1
        }
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    removeNavLinkUnder: {
        color: "inherit",
        textDecoration: "inherit"
    },
    removeCol: {
        color: "#ffffff"
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        padding: theme.spacing(0, 4),
        ...theme.mixins.toolbar,
        justifyContent: "center"
    },
    closeMenuAvatar: {
        marginLeft: "-16%",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        padding: theme.spacing(1, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-end"
    },
    userFullName: {
        textAlign: "center",
        paddingBottom: theme.spacing(2)
    },
    bio: {
        textAlign: "center",
        // paddingBottom: theme.spacing(2)
    },
    menuItems: {
        marginLeft: "5px"
    },
    customDivider: {
        borderStyle: "dashed",
        borderWidth: "0.2px",
        borderColor: "#c4b9b9"
    },
    inlineDivider: {
        borderWidth: "0.5px"
    },
    customBreak: {
        paddingTop: "25px"
    },
    notification: {
        marginRight: "20px"
    },
    logoutAvatar: {
        height: "2",
        width: "2",
        borderRadius: "50%"
    },
    title: {
        flexGrow: 1,
        marginRight: 20
    },
    rightToolbar: {
        marginLeft: 'auto',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
}));

export default function PersistentDrawerLeft(props) {
    debugger;
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [openWorksiteNav, setOpenWorksiteNav] = React.useState(true);
    const [openMenu, setOpenMenu] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [RoleType, SetRoleType] =  React.useState(false);

    
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [userBoardList, setUserBoardList] = React.useState([]);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);


    const handleProfileMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    /*const handleMobileMenuOpen = event => {
      setMobileMoreAnchorEl(event.currentTarget);
    };*/

    const handleNavListClick = (navLink) => {
        debugger;
        if (navLink === 'Worksite Tasks') {
        setOpenWorksiteNav(!openWorksiteNav);
        }
      };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleCloseMenuClick}>Logout</MenuItem>
            {/*<MenuItem onClick={handleMenuClose}>Profile</MenuItem>*/}
            {/*<MenuItem onClick={handleMenuClose}>My account</MenuItem>*/}
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton aria-label="show 11 new notifications" color="inherit">
                    <Badge badgeContent={11} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircleIcon />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );



    function handleOpenMenuClick() {
        debugger;
        setOpenMenu(openMenu => !openMenu);
    }
    const trailAccessAdminLogOutOauth= async (data) => {

        return  await axios({
            method: 'post',
            url: API_CONFIG.concat('/util/exitUserAuthTrailer'),
            data: data,
            headers: {'Access-Control-Allow-Headers': '*'}
        }).then(function (response) {
            console.log(response.data)
        });



    }

    const GetTrelloBoardNavigation= async (data) => {

        return  await axios({
            method: 'get',
            url: API_CONFIG.concat('/trello/getMemberTrelloBoards?key=' + data.key + '&token='+  data.token),
            headers: {'Access-Control-Allow-Headers': '*'}
        }).then(function (response) {
            console.log('getMemberTrelloBoards '+response.data)

            let metro = [],organisationList =[], boardList = [];
            const result = response.data;
            for (let i = 0; i < result.length; i++) {
                if (result[i].name !== 'Technical Support') { 
                    if(typeof result[i].idOrganization !== 'undefined'){
                        organisationList.push({'employerId':result[i].idOrganization, 'employername':'','boardName':result[i].name});   
                    }
                    boardList.push({'boardNameId':result[i].id, 'boardName':result[i].name,'employerId':result[i].idOrganization});   
                                        
            }}
            metro =  organisationList.filter((v,i,a)=>a.findIndex(t=>(t.employerId === v.employerId))===i);
            debugger;
            for (let index = 0; index < metro.length; index++) {
               // metro[index].employername
               /* axios.get( API_CONFIG.concat(`/trello/getMemberTrelloBoards?key=${data.key}&token=${data.token}&organizationId=${metro[index].employerId}`)).then(res => {

                    const persons = res.data; 
                    //metro[index].employername;
   
                   });*/
                
            }
            debugger;
            boardList.push({'employerInfo':metro})
            setUserBoardList(boardList);

        });
        
        
           



    }

    function handleCloseMenuClick(event) {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        document.cookie.split(";").forEach(function(c) {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        let authDTO = {
            admin_username: '',
            browser_type: '',
            created_at: '',
            device_type: '',
            id: 0,
            login_user_role: '',
            sessionID: localStorage.getItem('sessionid'),
            session_end_time: '',
            session_start_time: '',
            sys_os_model:'' ,
            username: ''
        };
        trailAccessAdminLogOutOauth(authDTO);

        localStorage.clear();
        sessionStorage.clear();
        props.history.push("/login");


        setOpenMenu(true);
    }


    function handleDrawerOpen() {
        setOpen(true);

    }

    function handleDrawerClose() {
        setOpen(false);
    }

    function alignAvatar(){
        if(open){
            return <div aria-label="settings" onClick={handleOpenMenuClick} className={classes.drawerHeader}>
                {userAvatarImage(KeyData)}
            </div>
        }else{
            return <div aria-label="settings" onClick={handleOpenMenuClick} className={classes.closeMenuAvatar}>
                {userAvatarImage(KeyData)}
            </div>
        }
    }

    function renderSlackChat (path) {
        path = path.split('/');
        console.log('Current Path: ' + path[1]);
        debugger;
        if (typeof path !== undefined && path.length > 1) {
            if (path[1].toString() === 'ScheduleCalendar') {
                return null;
            } else {
                return <SlackChatUI data={KeyData} />
            }
        }

    }

    function switchIcon(data) {
        switch (data) {
            case "Home":
                return <Tooltip title={"Home"} aria-label={"Home"}>
                    <Home className={classes.removeCol} />
                </Tooltip>;
            case "Dashboard":
                return <Tooltip title={"Dashboard"} aria-label={"Dashboard"}>
                    <SpeedIcon className={classes.removeCol} />
                </Tooltip>;
            case "Goals":
                return <Tooltip title={"Goals"} aria-label={"Goals"}>
                    <PlaceIcon className={classes.removeCol} />
                </Tooltip>;
            case "Calendar":
                return  <Tooltip title={"Calendar"} aria-label={"Calendar"}>
                    <TodayIcon className={classes.removeCol} />
                </Tooltip>;
            case "Notifications":
                return (
                    <Badge
                        badgeContent={5}
                        variant="standard"
                        color="primary"
                        showZero
                        className={classes.removeCol}
                    >
                        <NotificationsActiveIcon />
                    </Badge>
                );
            case "Worksite Tasks":
                return <Tooltip title={"Worksite Tasks"} aria-label={"Worksite Tasks"}>
                    <Business className={classes.removeCol} />
                </Tooltip>;
            case "Solutions":
                return <Tooltip title={"Solutions"} aria-label={"Solutions"}>
                    <Extension className={classes.removeCol} />
                </Tooltip>;
            case "Technical Support":
                return <Tooltip title={"Technical Support"} aria-label={"Technical Support"}>
                    <LiveHelpIcon className={classes.removeCol} />
                </Tooltip>;
            case "Tools":
                return <Tooltip title={"Tools"} aria-label={"Tools"}>
                    <BuildIcon className={classes.removeCol} />
                </Tooltip>;
            case "Assessment":
                return <Tooltip title={"Assessment"} aria-label={"Assessment"}>
                    <AssessmentIcon className={classes.removeCol} />
                </Tooltip>;
            default:
                return <SpeedIcon className={classes.removeCol} />;
        }
    }


    const parseJwt = token => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };
    const KeyData = { token: '', key: '' };
    debugger
    if (localStorage.getItem("mmih_auth_data")) {
        let user = window.atob(localStorage.getItem("mmih_auth_data")), user_data = [];
        user_data = user.split('::Flow::');
        if (user_data) {
            KeyData.token= user_data[1];
            KeyData.key= user_data[0];

        }
    }
    debugger;
    let employeeTypes
debugger;
  // console.log(GetJwt(sessionStorage.getItem("mmih_auth_token")).sub.compentency[0].competencyRolesResponse.primaryRole);

        if((GetJwt(sessionStorage.getItem("mmih_auth_token")).sub.employeeNumber)!==null)
        {
            debugger;
        let data = {
             employeeNumber: GetJwt(sessionStorage.getItem("mmih_auth_token")).sub.employeeNumber
        };


        //Get Role and assign to session
        PowerBiService.GetUserRole(data).then(res => {
            console.log(JSON.stringify(res))
            if(typeof res.data.primaryRole !== 'undefined') {
                console.log('GOT PRIMARY ROLE ---> ' + res.data.primaryRole);
                Cookies.set('role',  res.data.primaryRole);
                if( res.data.primaryRole==="BRANCH MANAGER"){
                    SetRoleType(true);
                } else{
                    SetRoleType(false);
                }
            }else{
                Cookies.set('role',  "No Role for " + data.employeeNumber);
            }

        });

       // GetTrelloBoardNavigation(KeyData);
       /* boardsService.GetTrelloBoardNavigation(KeyData)
            .then(res => res.data)
            .then(
                response => {

                    console.log('TRELLO BOARDS ----->' + JSON.stringify(response));

                    let metro = [];
                    for (let i = 0; i < response.length; i++) {
                        if (response[i].name !== 'Technical Support') { metro.push(response[i]) }
                    }
                    this.data.ObjectResponse = metro;

                    
                },
                error => {
                    this.setState({
                        overlay: true,
                        isLoaded: true,
                        error
                    });
                }
            );*/

    }else{
        console.log('NO ROLE EXIST')
    }
    console.log("Trello Token ---->" + KeyData.token);
    console.log("Trello Key ---->" + KeyData.key);




    let userLog = "",
        userLogIn = {};

    if (typeof Cookies.get("auth") !== "undefined") {
        userLog = parseJwt(Cookies.get("auth")).sub.displayName;
        userLogIn = parseJwt(Cookies.get("auth"));
    }
    const validateLogin = userLog => {
        return userLog !== null ? userLog : "Vusi Surname";
    };

    const userAvatarImage = KeyData => {
        return <Avatar data={KeyData} user={userLog} />;
    };

    const userAvatarUI = userAvatarImage(KeyData);

    function GetJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    function showLogo() {
        if (!open) {
            return <img src={appbarLogo} style={{ marginTop: "1px" }} width="116px" height="45px" alt="flow" />;
        }
    }
    const showRoleList =(accessRole, role)=>{
        let response = 'none';
          if(accessRole === false && role === 'user'){
            response = 'block';
          }
           if(accessRole){
             response = 'block';  
          }        
          return response;
        }
   
        const changeNavList = (navLink) =>{
            if (navLink === 'Worksite Tasks') {
                return (<div> 
                    <ListItemText primary={navLink} /> { openWorksiteNav ? <ExpandLess /> : <ExpandMore /> }
                </div>)
                
            } else {
                return  <ListItemText primary={navLink} />
            }
             
        };
        const showCollapseNavlist = (navLink) =>{
            return (navLink === 'Worksite Tasks') ? (
              <div>
                    <Collapse in={openWorksiteNav} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title={"Pamalat"} aria-label={"Pamalat"}>
                                        <StarBorder className={classes.removeCol} />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Starred" />
                            </ListItem>
                        </List>
                    </Collapse>
            
            </div>):'';
           }
       

    return (
        <HashRouter>
            <div className="comp-Layout">
                <div className={classes.root}>
                    <CssBaseline />
                    <AppBar
                        position="fixed"
                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: open
                        })}
                    >
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                className={clsx(classes.menuButton, {
                                    [classes.hide]: open
                                })}
                            >
                                <MenuIcon className={classes.menuButton} />
                            </IconButton>
                            <Typography style={{ paddingTop: "2px" }}>
                                {showLogo()}
                            </Typography>
                            <Grid className={classes.rightToolbar}>
                                {/*<IconButton  onClick={handleProfileMenuOpen} color="inherit"  aria-label="menu" style={{marginRight: '20px'}}>*/}
                                {/*  <ExitToAppIcon />*/}
                                {/*</IconButton>*/}
                                <Tooltip title={userLog} aria-label={userLog}>
                                    <Button
                                        variant="contained"
                                        // color="primary"
                                        size="small"
                                        className={classes.button}
                                        startIcon={<ExitToAppIcon />}
                                        onClick={handleCloseMenuClick}
                                        style={{marginRight: '20px', color: "#ffff", backgroundColor: "#673ab7"}}
                                    >
                                        Logout
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    {renderMenu}
                    {renderMobileMenu}

                    <Drawer
                        variant="permanent"
                        className={clsx(classes.drawer, {
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open
                        })}
                        classes={{
                            paper: clsx({
                                [classes.drawerOpen]: open,
                                [classes.drawerClose]: !open
                            })
                        }}
                        open={open}
                    >
                        <div className={classes.toolbar}>
                            <img src={logo} width="116px" height="45px" alt="flow" />
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === "ltr" ? (
                                    <ChevronLeftIcon className={classes.removeCol} />
                                ) : (
                                    <ChevronRightIcon />
                                )}
                            </IconButton>
                        </div>
                        <Divider className={classes.removeCol} />
                        <div >
                            {/*  avatar */}
                            {alignAvatar()}
                            {/*    <Popper open={openMenu} anchorEl={anchorRef.current} keepMounted transition disablePortal>*/}
                            {/*  {({ TransitionProps, placement }) => (*/}
                            {/*    <Grow*/}
                            {/*      {...TransitionProps}*/}
                            {/*      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}*/}
                            {/*    >*/}
                            {/*      <Paper id="menu-list-grow">*/}
                            {/*        */}
                            {/*          <MenuList autoFocusItem={openMenu} >*/}
                            {/*            <MenuItem onClick={handleCloseMenuClick}>Logout</MenuItem>*/}
                            {/*                           */}
                            {/*          </MenuList>*/}
                            {/*        */}
                            {/*      </Paper>*/}
                            {/*    </Grow>*/}
                            {/*  )}*/}
                            {/*</Popper>*/}
                        </div>
                        <Typography
                            variant="inherit"
                            component="h3"
                            className={classes.userFullName}
                        >
                            {validateLogin(userLog)}
                        </Typography>
                        <Divider
                            variant="middle"
                            light={false}
                            className={classes.customDivider}
                        />
                        <List>
                          {basicNavigate.map((NavigateText, index) => (
                                <NavLink
                                    key={index}
                                    className={classes.removeNavLinkUnder}
                                    to={"" + NavigateText.navPathLink + ""} style={{display:showRoleList(RoleType,NavigateText.role)}}
                                >
                                    <ListItem className={classes.menuItems} button key={index}>
                                        <ListItemIcon style={{display: showRoleList(RoleType,NavigateText.role) }} >{switchIcon(NavigateText.name)}</ListItemIcon>
                                        <ListItemText primary={NavigateText.name} style={{display: showRoleList(RoleType,NavigateText.role)}}  />
                                    </ListItem>
                                    <Divider variant="middle" className={classes.inlineDivider}  style={{display: showRoleList(RoleType,NavigateText.role)}} />
                                </NavLink>
                            ))}
                           {/* {basicNavigate.map((NavigateText, index) => (
                                <NavLink
                                    key={index}
                                    className={classes.removeNavLinkUnder}
                                    to={"" + NavigateText.navPathLink + ""}
                                >



                                    <ListItem className={classes.menuItems} button key={index}  >
                                        <ListItemIcon >{switchIcon(NavigateText.name)}</ListItemIcon>
                                        <ListItemText primary={NavigateText.name} />

                                    </ListItem>
                                    <Divider variant="middle" className={classes.inlineDivider} />
                                </NavLink>

                            ))}*/}
                            {basicBoardsNavigate.map((boardsNavigateText, index) => (
                                <NavLink
                                    key={index}
                                    className={classes.removeNavLinkUnder}
                                    to={"" + boardsNavigateText.navPathLink + ""}
                                >
                                    <ListItem button key={index} className={classes.menuItems} /*onClick={handleNavListClick(boardsNavigateText.name)}*/>
                                        <ListItemIcon>
                                            {switchIcon(boardsNavigateText.name)}
                                        </ListItemIcon>
                                        <ListItemText primary={boardsNavigateText.name} />
                                    </ListItem>
                                    {/*showCollapseNavlist(boardsNavigateText.name)*/}
                                    <Divider variant="middle" className={classes.inlineDivider} />
                                </NavLink>
                            ))}
                            <Divider variant="middle" className={classes.customDivider} />
                        </List>
                    </Drawer>
                    <main className={classes.content}>
                        <div className={classes.toolbar} />
                        <IdleTimerContainer {...props} />
                       
                        <Switch>
                        <Route
                            exact
                            path="/"
                            render={props => (
                                <HomeLayoutHub
                                    {...props}
                                    data={KeyData}
                                    userLog={userLogIn}
                                    userAvatarUI={userAvatarUI}
                                />
                            )}
                        />
                        <Route path="/Inbox" component={InboxLayoutHub} />
                        <Route path="/ScheduleCalendar"
                               render={props => <ToDoCalendarLayoutHub {...props} data={KeyData} />} />


                        <Route  style ="display:none"
                                path="/Dashboard"
                                component={DashboardLayoutHub}
                        />
                        <Route
                            path="/Boards"
                            render={props => <Boards {...props} data={KeyData}  />}
                        />

                        <Route
                            path="/Goals"
                            render={props => <SetGoals {...props} data={KeyData} />} />
                        <Route
                            path="/Solutions"
                            render={props => <GetUpSolution {...props} data={KeyData} />}
                        />
                        <Route
                            path="/Tools"
                            render={props => <ToolsLayout {...props} data={KeyData} />}
                        />
                        <Route
                            path="/Support"
                            render={props => <Supportboards {...props} data={KeyData} />}
                        />
                        <Route
                            path="/channel-metrix"
                            render={props => <AuditFlowDashboard {...props} data={KeyData}  />}
                        />
                        </Switch>
                        {/*{renderSlackChat(window.location.hash)}*/}
                    </main>
                    
                </div>
            </div>
        </HashRouter>
    );
}
