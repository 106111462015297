import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { BarChart, Bar, Cell,LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, CartesianGrid, Tooltip, Legend, } from 'recharts';
import {Paper, Grid,Card, CardContent, Typography,Avatar, Box, LinearProgress} from '@material-ui/core';
import clsx from 'clsx';
import Link from '@material-ui/core/Link';
import { Bar as CardStatusBar, Doughnut } from 'react-chartjs-2';
import themeChartCard from './themeChartCard';
import MUIDataTable from "mui-datatables";
import avatarService from '../../shared/AvatarService';
import boardsService from '../../shared/boardsService';
import CardsService from '../../shared/cardService';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import { tr } from 'date-fns/locale';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';


function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://metgetup.com/">
          Your Website
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400}];
const style = theme => ({
    root: {
      display: 'flex',
     /* flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,*/
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    tabRoot:{
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },  
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },   
    title: {
      flexGrow: 1,
    }, 
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(0),
      paddingRight:theme.spacing(0),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 200,
    },
    cardChartContainer: {
      height: 240,
      position: 'relative'
    },
    doughtChartContainer: {
      position: 'relative',
      height: 240,
    },
    doughtChartStats: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center'
    },
    device: {
      textAlign: 'center',
      padding: theme.spacing(1)
    },
    progress: {
      marginTop: theme.spacing(3)
    },
//
cardContent: {
  alignItems: 'center',
  display: 'flex'
},
cardTitle: {
  fontWeight: 700
},
cardAvatar: {
  backgroundColor: theme.palette.error.main,
  height: 56,
  width: 56,
  color:'white'
},
cardIcon: {
  height: 32,
  width: 32
},
cardDifference: {
  marginTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'center'
},
cardDifferenceIcon: {
  color: theme.palette.error.dark
},
cardDifferenceValue: {
  color: theme.palette.error.dark,
  marginRight: theme.spacing(1)
}

  });

  class CardDataBoard extends React.Component  {
    constructor(props) {
      super(props);
      this.boardsService = new boardsService();
      this.CardsService = new CardsService();
      this.avatarService = new avatarService();
      this.state = {
        cardColumnNames : [],
        cardColumnData : [],
        timeDuration:'',
        start_deposit_session:new Date(),
        value: 0,
        fiveWeekBar: [],
        totalCards:0,
        totalStoreCompleted:0,
        storeAddCardCompleted:0,
        storeAddCardCompletedName:'',
        cardDetailsDataDone:[],
        cardDetailsDataToDo:[],
        anchorEl: null,
        selectedIndex: 0,
        userBoardList: [{'boardNameId':0, 'boardName':'No board name to show','employerId': ''},
        {'boardNameId':1, 'boardName':'No board name to show','employerId': ''}],
        members:[],
        totalCardLaneListTitle: [{
          title: 'Done',
          value: '0',     
          color:{
            color: themeChartCard.primary.main,
            name: ''}
        },
        {
          title: 'Backlog',
          value: '0',
          color: {
            color:themeChartCard.error.main,
            name: ''}
        },
        {
          title: 'In progress',
          value: '0',     
          color: {
            color:themeChartCard.warning.main,
            name: ''}
        },
        {
          title: 'To do',
          value: '0',     
          color: {
            color:themeChartCard.success.dark,
            name: ''}
        }],
        totalCardLaneList:[],
        doughnutDataset:[0,0,0,0],
        doughnutDataBackgroundColor:[
          themeChartCard.primary.main,
          themeChartCard.error.main,
          themeChartCard.warning.main,
          themeChartCard.success.dark
        ],
      doughnutDataLabels: ['Backlog', 'To do','In progress', 'Done']
      }
      debugger;
    }

    componentDidMount() {
      this.loadAllCardInfo();
      this.generateWeekView();      
      this.fetchTrelloBoards();
    }

    generateWeekView = () =>{
  debugger;
  const currentDate = new Date(); // get current date
  const first = currentDate.getDate() - currentDate.getDay();
  let fiveWeek= [],fiveWeekHolder = [];
  for (var i = 1; i < 7; i++) {
      let next = new Date(currentDate.getTime());
      next.setDate(first + i);
      fiveWeekHolder.push(next);
      let response = next.toString().split(' ');
      let responseDate = response[0]+' '+response[1]+' '+response[2];
      fiveWeek.push(responseDate)
  }

  let tryout =[];
  for (let jk = 0; jk < fiveWeekHolder.length; ++jk) {
    let numOfDone = 0, numOfTodo = 0;
    for (let j = 0; j < this.state.cardColumnData.length; ++j) {
      if (this.state.cardColumnData[j].targetLaneName === 'Done' && (fiveWeekHolder[jk].toJSON().slice(0, 10).replace(/-/g, '/') === new Date(this.state.cardColumnData[j].dateCreated).toJSON().slice(0, 10).replace(/-/g, '/'))) {
        ++numOfDone;
      }
       if ((this.state.cardColumnData[j].targetLaneName === 'To do' || this.state.cardColumnData[j].targetLaneName === 'In Progress') && (fiveWeekHolder[jk].toJSON().slice(0, 10).replace(/-/g, '/') === new Date(this.state.cardColumnData[j].dateCreated).toJSON().slice(0, 10).replace(/-/g, '/'))) {
        ++numOfTodo;
      }
    }
    tryout.push({entry:jk,dateValue:fiveWeekHolder[jk],no_num_done:numOfDone,no_num_todo:numOfTodo});
    
  }
  let cardDetailsDataDone = [], cardDetailsDataToDo = [];
  tryout.forEach(function(entry) {
    cardDetailsDataDone.push(entry.no_num_done);
    cardDetailsDataToDo.push(entry.no_num_todo); 
});
  
  

  this.setState({
    fiveWeekBar: fiveWeek,
    cardDetailsDataDone:cardDetailsDataDone,
    cardDetailsDataToDo:cardDetailsDataToDo
  });

    }
    loadAllCardInfo =() =>{
  const parseJwt = token => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};

debugger;
    const access_name = parseJwt(localStorage.getItem('mmih_auth_token')).sub.sAMAccountName;
    this.avatarService.flowProcessTask({'access_name': access_name}).then(res=>{
      const auditData = [];
      const storeCompleted = [];
      let storeAddCardCompleted = 0, storeAddCardCompletedName = '';
      for(let d = 0; d < res.data.length; d++ ){
        let sameCardId = false;
        if(res.data[d].targetLaneName !== null){  
                    storeCompleted.push({completeDate:res.data[d].dateCreated,fullResponse:res.data[d],sameCardId:sameCardId,cardId:res.data[d].cardId,targetLaneName:res.data[d].targetLaneName,dateCreated: new Date(res.data[d].dateCreated)});
        }
        if(res.data[d].eventType ==='ADD_CARD' && (new Date().toJSON().slice(0,10).replace(/-/g,'/') === new Date(res.data[d].dateCreated).toJSON().slice(0,10).replace(/-/g,'/') )){
          ++storeAddCardCompleted;
          storeAddCardCompletedName = res.data[d].samaccountName;
         }
        auditData.push({cardId:res.data[d].cardId ,eventType:res.data[d].eventType,samaccountName:res.data[d].samaccountName,sourceLaneName:res.data[d].sourceLaneName,
          targetLaneName:res.data[d].targetLaneName,memberType:res.data[d].memberType,dateCreated:res.data[d].dateCreated});
      }      
      debugger;
      //const totalCards =[...new Set(auditData)];
      

      const totalCards = auditData.filter((v,i,a)=>a.findIndex(t=>(t.cardId === v.cardId && (t.eventType === 'ADD_CARD' && new Date(t.dateCreated).getMonth()=== new Date().getMonth())))===i);
      const totalStoreCompleted = storeCompleted.filter((v,i,a)=>a.findIndex(t=>(t.cardId === v.cardId))===i);
      let tempTotalStoreCompletedStore = [];
      for (let index = 0; index < totalStoreCompleted.length; index++) {
        const result = storeCompleted.filter(word => word.cardId === totalStoreCompleted[index].cardId);
        const sortedActivities = result.slice().sort((a, b) => b.dateCreated - a.dateCreated);
        if(sortedActivities.length > 0){
          if(sortedActivities[0].targetLaneName === 'Done' && sortedActivities[0].dateCreated.getMonth() === new Date().getMonth() ){
            tempTotalStoreCompletedStore.push(sortedActivities[0]);
          }
        }
      }
      debugger;
      this.setState({
        cardColumnData:auditData,
        totalCards:totalCards.length,
        totalStoreCompleted:tempTotalStoreCompletedStore.length,
        storeAddCardCompleted:storeAddCardCompleted,
        storeAddCardCompletedName:storeAddCardCompletedName,
      });
      this.generateWeekView();
    },error => {
      this.setState({
        cardColumnData:[]
      });

    });
    }

    laneColor = (color) => {
      let newColor ={color:'',name:''};
      debugger;
      switch (color) {
        case 'Done':
        case 'done':
          //newColor = themeChartCard.primary.main;
          newColor ={color: themeChartCard.primary.main,name:'_done'};
          break;
        case 'Backlog':
        case 'Back-log':
          //newColor = themeChartCard.error.main;
          newColor ={color: themeChartCard.error.main ,name:'_backlog'};
          break;
        case 'In progress':
        case 'In Progress':        
          //newColor = themeChartCard.warning.main;
          newColor ={color: themeChartCard.warning.main,name:'_in_progress'};
          break;
        case 'To do':
        case 'To Do':
        case 'To-do':
          //newColor = themeChartCard.success.dark; 
          newColor ={color:themeChartCard.success.dark,name:'_to_do'};
        break;      
        default:
          //newColor = '';
          newColor ={color:'',name:''};
          break;
      }
      return newColor;
    }

  fetchTrelloCardsPerLane = (carry, boardId) => {

    this.setState({
        cards: null,
        overlay: true, tabClick: false, openCard: false
    });
    this.CardsService
        .GetAllTrelloCardsPerLane(this.props.data, boardId)
        .then(res => {
          let userBoards = [],doughnutDataset=[],doughnutDataBackgroundColor=[], doughnutDataLabels=[], cards = res.data;
            if(cards.lanes.length > 0){
              debugger;
              for (let index = 0; index < cards.lanes.length; index++) {
                debugger;
                userBoards.push({'id':cards.lanes[index].id,"title":cards.lanes[index].title,'value': cards.lanes[index].cards.length,'color': this.laneColor(cards.lanes[index].title)});
                doughnutDataset.push(userBoards[index].value);
                doughnutDataBackgroundColor.push(userBoards[index].color.color);
                doughnutDataLabels.push(userBoards[index].title);
              }
            }
            this.setState({
              totalCardLaneListTitle: userBoards,
              totalCardLaneList: cards.lanes,
              doughnutDataset:doughnutDataset,
             doughnutDataBackgroundColor:doughnutDataBackgroundColor,
             doughnutDataLabels: doughnutDataLabels
            });
            debugger;
            const memberData = {
                boardId: boardId,
                token: this.props.data.token,
                key: this.props.data.key
            }
            this.GetAllMembersPerBoards(memberData);           
  },
  error => {
                this.setState({
                  totalCardLaneListTitle: [],
                  totalCardLaneList:[],
                    overlay: false,
                    error
                });
            }
        );
}

GetAllMembersPerBoards = (MemberData) => {


  console.log(" ALL PROP DATA INFORMATION" + JSON.stringify(MemberData));
  this.CardsService
      .GetAllMembersPerBoard(MemberData)
      .then(res => {
        debugger;
          this.setState({ members: res.data });
      })
      .catch(error => {
          console.log(error);
          
      });

}

 /*FETCHES TRELLO BOARD NAVIGATION*/
 fetchTrelloBoards = () => {
   debugger;
  this.boardsService
      .GetTrelloBoardNavigation(this.props.data)
      .then(res => res.data)
      .then(response => {
              let organisationList =[], boardList = [];
              for (let i = 0; i < response.length; i++) {
                  if (response[i].name !== 'Technical Support') { 
                      if(typeof response[i].idOrganization !== 'undefined'){
                          organisationList.push({'employerId':response[i].idOrganization});   
                      }
                      boardList.push({'boardNameId':response[i].id, 'boardName':response[i].name,'employerId':response[i].idOrganization});   
                      }
              }
              boardList.push({'employerInfo':organisationList.filter((v,i,a)=>a.findIndex(t=>(t.employerId === v.employerId))===i)});              
              this.setState({userBoardList:boardList});
              this.fetchTrelloCardsPerLane(boardList, boardList[0].boardNameId);
          },
          error => {
              this.setState({
                  userBoardList: [{'boardNameId':1, 'boardName':'No board name to show','employerId': ''}],
                  isLoaded: true,
                  error
              });
          }
      );
}  


    render() {
        const {classes} = this.props;
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const cardDetailsData = {
    labels: this.state.fiveWeekBar,
    datasets: [
      {
        label: 'Done',
        backgroundColor: themeChartCard.primary.main,
        data: this.state.cardDetailsDataDone
      },
      {
        label: 'In progress(to do)',
        backgroundColor: themeChartCard.neutral,
        data: this.state.cardDetailsDataToDo
      }
    ]
  };
  
  const cardDetailsOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    legend: { display: false },
    cornerRadius: 20,
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: themeChartCard.divider,
      backgroundColor: themeChartCard.white,
      titleFontColor: themeChartCard.text.primary,
      bodyFontColor:themeChartCard.text.secondary,
      footerFontColor: themeChartCard.text.secondary
    },
    layout: { padding: 0 },
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: themeChartCard.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: themeChartCard.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: themeChartCard.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: themeChartCard.divider
          }
        }
      ]
    }
  };

  const doughnutData = {
    datasets: [
      {
        data: this.state.doughnutDataset/*[51, 15, 22,32]*/,
        backgroundColor: this.state.doughnutDataBackgroundColor,
        borderWidth: 8,
        borderColor: themeChartCard.white,
        hoverBorderColor: themeChartCard.white
      }
    ],
    labels: this.state.doughnutDataLabels/*['Done', 'In progress', 'backlog','To do']*/
  };

  const boardsDropdownManagerOptions = [
    {'boardName':'Show some love to Material-UI','boardNameId':0},
    {'boardName':'Show all notification content','boardNameId':1},
    {'boardName':'Hide sensitive notification content','boardNameId':2},
    {'boardName':'Hide all notification content','boardNameId':3},
  ];

  const handleClickListItem = (event) => {

    this.setState({
      anchorEl: event.currentTarget
    });
   // setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index,boardId) => {


    debugger;
    this.setState({
      anchorEl: null,
      selectedIndex: index
    });
    this.fetchTrelloCardsPerLane('', boardId);
    
  };

  const handleClose = () => {
    this.setState({
      anchorEl: null      
    });
  };
  

  const doughnutOptions = {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: themeChartCard.divider,
      backgroundColor: themeChartCard.white,
      titleFontColor: themeChartCard.text.primary,
      bodyFontColor: themeChartCard.text.secondary,
      footerFontColor: themeChartCard.text.secondary
    }
  };

  const laneNames = [
    {
      title: 'Done',
      value: '63',     
      color: themeChartCard.primary.main
    },
    {
      title: 'Backlog',
      value: '15',
            color: themeChartCard.error.main
    },
    {
      title: 'In progress',
      value: '23',     
      color: themeChartCard.warning.main
    },
    {
      title: 'To do',
      value: '32',     
      color: themeChartCard.success.dark
    }
  ];



  const bottomOptions = {
    filterType: "dropdown",
    responsive: "scroll",
    onRowClick: (action, tableState) => {
      debugger;

      console.log(action, tableState);
     // this.populateDepositData(action[0], action[1], action[2], action[3], action[4], action[5]);
      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

     /* switch (action) {
        case 'changePage':
          this.changePage(tableState.page);
          break;
      }*/
    },
    onRowsSelect: (currentRowSelectedAction, allRowsSelectedAction) => {
      debugger;

      console.log(currentRowSelectedAction, allRowsSelectedAction);
     // this.populateDepositData(action[0], action[1], action[2], action[3], action[4], action[5]);
     
    }
  };
  
      const cardColumnNames = [{
        name: "cardId", label: "Card ID",
        options: {
          filter: true,
          sort: false,
        }
      }, {
        name: "eventType", label: "Card Actions",
        options: {
          filter: true,
          sort: false,
        }
      }, {
        name: "samaccountName", label: "Card Owner",
        options: {
          filter: true,
          sort: false,
        }
      }, {
        name: "sourceLaneName", label: "Source Lane Name",
        options: {
          filter: true,
          sort: false,
        }
      }, {
        name: "targetLaneName", label: "Target Lane Name",
        options: {
          filter: true,
          sort: false,
        }
      }, {
        name: "memberType", label: "User role",
        options: {
          filter: true,
          sort: false,
        }
      }, {
        name: "dateCreated", label: "Date Created",
        options: {
          filter: true,
          sort: true,
        }
      }];  
const averageCardProgressResponse = (this.state.totalCards > 0)?Math.round((this.state.totalStoreCompleted/this.state.totalCards)* 100)/100: 0;

  
  return (
  <div>
      <main className={classes.content}>
        <div /*className={classes.appBarSpacer}*/ />
        
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item lg={3} sm={6} xl={3}  xs={12}  >
            <Card className={fixedHeightPaper}>
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.cardTitle}
              color="textSecondary"
              gutterBottom
              variant="body2"
              color="primary"
            >
              CARDS COMPLETED
            </Typography>
  <Typography variant="h3">{this.state.totalStoreCompleted}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.cardAvatar}>
              <MoneyIcon className={classes.cardIcon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.cardDifference}>
          <ArrowDownwardIcon className={classes.cardDifferenceIcon} />
          <Typography
            className={classes.cardDifferenceValue}
            variant="body2"
          >
            12%
          </Typography>
          <Typography
            className={classes.cardCaption}
            variant="caption"
          >
            Since last month
          </Typography>
        </div>
      </CardContent>
    </Card>
            </Grid>
            {/* Chart */}
            <Grid item lg={3} sm={6} xl={3}  xs={12} >
            <Card className={fixedHeightPaper}>
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.cardTitle}
              color="textSecondary"
              gutterBottom
              variant="body2"
              color="primary"
            >
              TOTAL No. ADDED CARDS
            </Typography>
            <Typography variant="h3">{this.state.totalCards}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.cardAvatar}>
              <PeopleIcon className={classes.cardIcon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.cardDifference}>
          <ArrowUpwardIcon className={classes.cardDifferenceIcon} />
          <Typography
            className={classes.cardDifferenceValue}
            variant="body2"
          >
            16%
          </Typography>
          <Typography
            className={classes.cardCaption}
            variant="caption"
          >
            Since last month
          </Typography>
        </div>
      </CardContent>
    </Card>
            </Grid>
            {/* Recent Deposits */}
            <Grid item lg={3} sm={6} xl={3}  xs={12} >
            <Card    className={fixedHeightPaper}>
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.cardTitle}
              color="textSecondary"
              gutterBottom
              variant="body2"
              color="primary"
            >
              CARDS PROGRESS
            </Typography>
            <Typography variant="h3">{`${averageCardProgressResponse}%`}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.cardAvatar}>
              <InsertChartIcon className={classes.cardIcon} />
            </Avatar>
          </Grid>
        </Grid>
        <LinearProgress
          className={classes.progress}
          value={averageCardProgressResponse }
          variant="determinate"
        />
      </CardContent>
    </Card>
            </Grid>
            {/* Recent Deposits */}
            <Grid item lg={3} sm={6} xl={3}  xs={12} >
            <Card className={fixedHeightPaper}>
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.cardTitle}
              color="textSecondary"
              gutterBottom
              variant="body2"
              color="primary"
            >
              Today Card Created
            </Typography>
            <Typography variant="h3">{this.state.storeAddCardCompleted}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.cardAvatar}>
              <ConfirmationNumberIcon className={classes.cardIcon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.cardDifference}>
          
          <Typography
            className={classes.cardCaption}
            variant="caption"
          >
            {`last created by: ${this.state.storeAddCardCompletedName}`}
          </Typography>
        </div>
      </CardContent>
    </Card>
            </Grid>
            <Grid item lg={8} md={12} xl={9} xs={12}>
              <Paper className={classes.paper}>
              <Typography component="h2" variant="h6" color="primary" gutterBottom>Creation vs Completion</Typography>
              <div className={classes.chartContainer}>
                <CardStatusBar
                  data={cardDetailsData}
                  options={cardDetailsOptions}
                />
              </div>
                 </Paper>
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12}>
            <Paper className={classes.paper}> 
            <Typography component="h2" variant="h6" color="primary" gutterBottom>Board Completed tasks</Typography>
            <List component="nav" aria-label="Device settings">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="View Boards"
          onClick={handleClickListItem}>
            
          <ListItemText primary="View Boards" secondary={this.state.userBoardList[this.state.selectedIndex].boardName} />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={this.state.anchorEl}
        keepMounted
        open={Boolean(this.state.anchorEl)}
        onClose={handleClose}
      >
        {this.state.userBoardList.map((option, index) => (
          
          <MenuItem
            key={index}
            
            selected={index === this.state.selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index,option.boardNameId)}
          >
            {option.boardName}
          </MenuItem>
        ))}
      </Menu>
              <div className={classes.chartContainer}>
                <Doughnut
                  data={doughnutData}
                  options={doughnutOptions}
                />
              </div>
              <div className={classes.doughtChartStats}>
                {this.state.totalCardLaneListTitle.map(laneName => (
                  <div className={classes.device} key={laneName.title}>                   
                    <Typography variant="body1">{laneName.title}</Typography>
                    <Typography style={{ color: laneName.color.color}} variant="h6">{laneName.value}%</Typography></div>))}
              </div>            
            </Paper>
            </Grid>

            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
              <Typography component="h2" variant="h6" color="primary" gutterBottom>Task Content</Typography>
              <MUIDataTable
                //title={"Flow duration data"}
                data={this.state.cardColumnData}
                columns={cardColumnNames}
                options={bottomOptions}
              /> 
                
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
       
      </main>
  </div>
  
  );
    
    }


}
export default withStyles(style)(CardDataBoard);