import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2
      },
    media: {
        height: 195,
        borderStyle: "hidden",
        backgroundSize: "cover"

    },
    media2: {
        height: 190,
    },
    companyLogo: {
        display: "flex",
        flexDirection: "row",
        marginLeft: "-10px",
        // paddingBottom: 0.5,
        backgroundSize: "cover"

    },
    wrapSolutionsText: {
        // whiteSpace: "nowrap",
        // textOverflow: "ellipsis",
        // maxWidth: "20px",
        wordWrap: "break-word",
    },
    cardButton: {
        paddingTop: "15px"
    }
});

export default function CardThirdPartyEntity(props) {
  const classes = useStyles();
  const raiseInvoiceClicked = (event, data) => {
    const url = props.data.url;
    window.open(url, '_blank');
  };
  const raiseServiceClicked = (event, data) => {
    const url = 'https://app.root.co.za';
    window.open(url, '_blank');
  };
  const logo = props.data.organizationLogo === '';
  
  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={props.data.image}
          onClick={(e) => {raiseInvoiceClicked(e, props) }}
        />
        <CardContent className={classes.media2} onClick={(e) => {raiseInvoiceClicked(e, props) }}>
            <Grid container direction={"row"} alignItems={"center"}>
                <Grid item  style={logo ?{display: 'none'}:{display: 'block'}}>
                    <Avatar src={props.data.organizationLogo}  className={classes.companyLogo}  alt={"comp"}/>
                </Grid>
                <Grid item>
                    <Typography style={{marginLeft: 4, marginTop: 1}} variant="h6" component="h2">
                        {props.data.organizationTag}
                    </Typography>
                    <Typography style={{marginLeft: 6, marginTop: 1}}  variant={"caption"} component={"label"}>
                        {props.data.organizationName}
                    </Typography>
                </Grid>
            </Grid>
            <br/>
          <Typography variant="body2" className={classes.wrapSolutionsText} color="textSecondary" component="p">
            {props.data.organizationQuotes}
          </Typography>
        </CardContent>
      </CardActionArea>
        <br/>
      <CardActions className={classes.cardButton}>
        <div>
          {
             props.isTools ? (
                 <Button variant="outlined" size="small" color="primary" onClick={(e) => {raiseInvoiceClicked(e, props) }}>
                     View More
                 </Button>
             ) : (
                 <Button variant="outlined" size="small" color="primary" onClick={(e) => {raiseInvoiceClicked(e, props) }}>
                    {(props.data.url.startsWith('https://www.metgetup.co.za/blog/'))?'Read More':'Apply'} 
                 </Button>
             )
          }
          </div>
          <div style={{float: "right",marginLeft: '50%'}}>
          {
             props.data.organizationTag === 'Income Protection' ? (
                 <Button variant="outlined" size="small" color="primary" onClick={(e) => {raiseServiceClicked(e, props) }}>
                     Service
                 </Button>
             ) : (
                 <Button style={{display: "none"}} variant="outlined" size="small" color="primary" onClick={(e) => {raiseInvoiceClicked(e, props) }}>
                     Apply
                 </Button>
             )
          }
          </div>

      </CardActions>
    </Card>
  );
}
