import React, { useRef, useState } from 'react'
import IdleTimer from 'react-idle-timer';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {API_CONFIG} from "../../config/api-config";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function IdleTimerContainer(props) {
    const idleTimerRef = useRef(null);
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [modalIsopen, setModalIsopen] = useState(false);
    const [isTimedOut, setIsTimedOut] = React.useState(false);
    const sessionTimeoutRef = useRef(null);

    const onIdle = () => {
        console.log('user is idle');
        setModalIsopen(true);
        sessionTimeoutRef.current = setTimeout(logOut, 5000);
    }
    const onAction = (e) => {
        console.log('user did something', e);
        setIsTimedOut(false);
    }

    const onActive = (e) => {
        console.log('user is active', e)
        //  console.log('time remaining', idleTimerRef.getRemainingTime());
        setIsTimedOut(false);
    }
    const StayAlive = () => {
        console.log('user is Stay Alive');
        debugger;
        setModalIsopen(false);
        clearTimeout(sessionTimeoutRef.current);
    }
    const logOut = () => {
        console.log('user is log out');
        debugger;
        setModalIsopen(false);
        setIsLoggedIn(false);
        let authDTO = {
            admin_username: '',
            browser_type: '',
            created_at: '',
            device_type: '',
            id: 0,
            login_user_role: '',
            sessionID: localStorage.getItem('sessionid'),
            session_end_time: '',
            session_start_time: '',
            sys_os_model:'' ,
            username: ''
        };
debugger;
        trailAccessAdminLogOutOauth(authDTO);

        localStorage.clear();
        sessionStorage.clear();
        props.history.push("/login");

        clearTimeout(sessionTimeoutRef.current);
    }

    const trailAccessAdminLogOutOauth= async (data) => {

        return  await axios({
            method: 'post',
            url: API_CONFIG.concat('/util/exitUserAuthTrailer'),
            data: data,
            headers: {'Access-Control-Allow-Headers': '*'}
        }).then(function (response) {
            console.log(response.data)
        });



    }

    const handleClose = () => { setModalIsopen(false); }
    return (
        <div>
            <Dialog
                open={modalIsopen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                disableBackdropClick 
                disableEscapeKeyDown
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>You've been idle for a while</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        You will be loggged out shortly
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={StayAlive}>keep me signed in</Button>
                    <Button variant="contained" color="primary" onClick={logOut}>leave session</Button>
                </DialogActions>
            </Dialog>

            <IdleTimer ref={idleTimerRef}
                timeout={1000 * 60 * 15}
                element={document}
                onActive={onActive}
                onIdle={onIdle}
                onAction={onAction}
                debounce={250}></IdleTimer>
        </div>
    )
}

export default IdleTimerContainer
