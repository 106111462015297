import React, { Component } from 'react';
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Board from 'react-trello';
import CardsService from '../../shared/cardService';
import boardsService from '../../shared/boardsService';
import Loader from 'react-loader-spinner';
import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import "../style.css";
import CustomizedDialogs from './../worksiteDialogbox';
import OutcomesDialogs from './../outcomesDialogbox';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadingOverlay from 'react-loading-overlay';
import * as Cookies from "js-cookie";
/*import PropagateLoader from 'react-spinners/PropagateLoader'
import NewCardForm from "react-trello/dist/components/NewCardForm";*/
import CustomCardForm from '../CustomCardForm';
import {
    Button, Input, Dialog, DialogContent, List, ListItem, Menu,MenuItem, IconButton, Checkbox,
    ListItemText,ListItemIcon, Divider, ListItemAvatar, Avatar, DialogTitle, Grid, Card, CardContent, CardActions, Paper, DialogActions,
    CardHeader, Chip, TextField,Tooltip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LabelIcon from '@material-ui/icons/Label';
import EventIcon from '@material-ui/icons/Event';
import AssignmentIcon from "@material-ui/icons/Assignment";
import ShortTextIcon from '@material-ui/icons/ShortText';
import SubjectIcon from '@material-ui/icons/Subject';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import DummyAvator from '../../avatar/smartfox@640.png';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';




const styles = theme => ({
    root: {
        flexGrow: 1
    },
    container: {
        flexGrow: 1,
        position: 'relative',
    },
    paper: {
        marginRight: theme.spacing(1),
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,

    },
    inline: {
        display: 'inline',
    },

    cardRoot: {
        display: 'flex',
    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'inherit',
    },
    cardContent: {
        flex: '1 0 auto',
    },
    cardCover: {
        width: 151,
    },
    cardLarge: {
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
    cardHeaderTitle: {
        backgroundColor: '#f2effc !important',
        padding: '2px'
    },
    cardContentInfo: {
        padding: '4px'
    },
    cardTextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 500,
    },
    cardAddMember: {
        marginLeft: "39px",
        marginTop: "15px",
        width: 510
    },
    avatarListCklr:{
        backgroundColor:'rgb(126, 57, 251)',
        color:'white'

    },
    wrapper:{
        margin: '0 auto',
        display: 'block'
    },
    textfieldWidth: {
        width: '100%',
      },
    linkEmployerWidth: {
        width: '25%',
        display: 'inline-block'
      },



});



function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
     // onClick={}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: 'rgb(126, 57, 251)',
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

class Boards extends Component {
    constructor(props) {
        super(props);
        this.boardsService = new boardsService();
        this.CardsService = new CardsService();
        this.state = {
            overlay: false,
            cards: null,
            isLoaded: true,
            boards: [],
            value: 0,
            myCardData: '',
            openOutcome: false,
            openCard: false,
            openCardInfo: {
                cardId: null,
                cardDesc: null,
                cardContent: null
            },
            tabClick: false,
            members: [],
            employeeNo: '',
            samaccountName: '',
            HideShowOffice365Date: '',
            isActive: false,
            addCardToLaneFlag: false,
            addCardToLaneByCardId: '',
            addCardToLaneByLaneId: '',
            searchValueQuery: '',
            openSearchDialog: false,
            selectedSearchIndex: 0,
            SearchAllTrelloCardsResultSet: [],
            selectedSearchIndexObject: '',
            openCardInfoContent: null,
            // search states
            _CheckedOffice365: true,
            _startDate: '',
            _HideShowOffice365Date: '',
            _cardDesc: '',
            _CheckboxReadOnly: false,
            _dueDate: '',
            _title: '',
            _TaskData: [],
            _taskId: '',
            _TaskTypeState: '',
            _currentBoardMembers: [],
            _currentCardMembers: [],
            _isLoaded: false,
            _searchCardId:'',
            _searchShowCardInfo:false,
            _searchErrorCardMessage:'',
            _searchSaveBtnStatus:true,
            _searchCardBoardName: '',
            anchorEl:null,
            _searchLaneCardValue:{id:'',name:''},
            _searchGetAllLanesPerboard:[],
            _selectedLaneSearchIndex:0,
            _searchBoardsInfo: [],
            _addRemoveMemberShowCardInfo:false,
            _AddRemoveMemberSaveBtnStatus:true,
            selectedAddRemoveMemberIndex: 0,
            selectedAddRemoveMemberIndexObject: '',
            _addRemoveMemberBoardMembers:[],
            openAddRemoveDialog:false,
            _addRemoveMemberText_add_email: '',
            _addRemoveMemberText_add_emailItem: [],
            _addRemoveMemberText_add_emailValue: '',
            error: null,
            _addRemoveMember:'',
            boardEmployerID:[],
            userBoardList:[],
            anchorElEmployers: null,
            selectedEmployersIndex: 0,
            userEmployersBoardList: [{'employer_index':0, "employer_id": "0",
            "created_by": "0",
            "is_active": true,
            "created_at": "0",
            "employer_display_name": "Retrieving Employers"},
            {'employer_index':1, "employer_id": "0",
            "created_by": "0",
            "is_active": true,
            "created_at": "0",
            "employer_display_name": "Retrieving Employers"}],
        };
        debugger;

    }
    componentDidMount() {
        window.scrollTo(0, 0)

        this.setState({
            "openOutcome": false,

        });

        this.CardsService.GetCalendarO365(this.props.data).
            then(res => res.data)
            .then(
                response => {
                    sessionStorage.setItem("mmih_accessToken", response.user_access_token);
                    localStorage.setItem('mmih_accessToken', response.user_access_token);
                },
                error => {
                    toast.notify("Error retrieving data" + error, {
                        duration: 800
                    });
                }
            );

        const parseJwt = token => {
            try {
                return JSON.parse(atob(token.split(".")[1]));
            } catch (e) {
                return null;
            }
        };

        this.fetchTrelloBoards()
        this.setState({
            employeeNo: parseJwt(localStorage.getItem('mmih_auth_token')).sub.employeeNumber,
            samaccountName: parseJwt(localStorage.getItem('mmih_auth_token')).sub.sAMAccountName
        }
        )

        //Validate
        ValidatorForm.addValidationRule('_isStartDateCaptured', (value) => {
            if ((value === 'Invalid date') || (value === '') || (value === undefined)) { return false; }
            return true;
        }
        );


        //Validate  Due
        ValidatorForm.addValidationRule('_isDueDateCaptured', (value) => {
            debugger;
            if ((value === 'Invalid date') || (value === '') || (value === undefined)) { return false; }
            return true;
        });

        //Validate
        ValidatorForm.addValidationRule('_isEndDateGreaterStartDateCheck', (value) => {
            // alert('Start Date ' + this.state.startDate + ' ' +  'End Date' +  value )
            if (this.state._CheckedOffice365) {
                let isafter = moment(value).isAfter(this.state._startDate);
                if (isafter === false) { return false; }
            }
            return true;
        });

        //Validate  Task Type
        ValidatorForm.addValidationRule('_isTaskTypeSelected', (value) => {
            if (value === 'SELECT A TASK TYPE') { return false; }
            return true;
        });

        //Validate  Task Type
        ValidatorForm.addValidationRule('_isSubject', (value) => {
            if (value === '') { return false; }
            return true;
        });


    }

    handleAddRemoveMemberPasteEmail = evt => {
        evt.preventDefault();
        debugger;
        var paste = evt.clipboardData.getData("text");
        var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

        if (emails) {
          var toBeAdded = emails.filter(email => !this.isInList(email));

          this.setState({
            _addRemoveMemberText_add_emailItem: [...this.state._addRemoveMemberText_add_emailItem, ...toBeAdded],
            _AddRemoveMemberSaveBtnStatus:true
          });
        }
      };

     handleAddRemoveMemberChangeEmail = evt => {
         debugger;
        this.setState({
          _addRemoveMemberText_add_emailValue: evt.target.value,
          _addRemoveMemberText_add_email: evt.target.value,
          error: null
        });
      };

      handleAddRemoveMemberDeleteEmail = item => {
          debugger;
        this.setState({
          _addRemoveMemberText_add_emailItem: this.state._addRemoveMemberText_add_emailItem.filter(i => i !== item)
        });
      };

      handleAddRemoveMemberKeyDown = evt => {
          debugger;
        if (["Enter", "Tab", ","].includes(evt.key)) {
          evt.preventDefault();

          var value = this.state._addRemoveMemberText_add_emailValue.trim();

          if (value && this.isValid(value)) {
            this.setState({
              _addRemoveMemberText_add_emailItem: [...this.state._addRemoveMemberText_add_emailItem, this.state._addRemoveMemberText_add_emailValue],
              _addRemoveMemberText_add_emailValue: "",
              _addRemoveMemberText_add_email: '',
              _AddRemoveMemberSaveBtnStatus:true
            });
          }
        }
      };

      isValid(email) {
        let error = null;

        if (this.isInList(email)) {
          error = `${email} has already been added.`;
        }

        if (!this.isEmail(email)) {
          error = `${email} is not a valid email address.`;
        }

        if (error) {
          this.setState({ error });

          return false;
        }

        return true;
      }

      isInList(email) {
        return this.state._addRemoveMemberText_add_emailItem.includes(email);
      }

      isEmail(email) {
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
      }




    data = {
        token: this.props.data.token,
        key: this.props.data.key,
        boardId: this.props.boardId,
        ObjectResponse: ''
    }

    searchInitialCaptureData = {
        cardDescript: '',
        cardDueDate:'',
        cardTaskType:'',
        cardTitle:''
        }

    showDialogContentCancel = () => {
        debugger;
        this.setState({
            searchValueQuery: '',
            _searchShowCardInfo:false,
            openSearchDialog: false,
             SearchAllTrelloCardsResultSet:[],
            selectedSearchIndex: 0,
            _searchErrorCardMessage:'',
            _searchLaneCardValue:{id:'',name:''},
            _searchGetAllLanesPerboard:[],
            anchorEl:null,
            overlay: true

        });
        this.fetchTrelloBoards();
    }

    showAddRemoveDialogContentCancel = () => {
        debugger;
        this.setState({
            _addRemoveMemberBoardName: '',
            _addRemoveMemberShowCardInfo:false,
            openAddRemoveDialog: false,
            _addRemoveMemberBoardMembers:[],
             selectedAddRemoveMemberIndex: 0,
             _addRemoveMemberErrorCardMessage:'',
            anchorEl:null,
            overlay: true

        });
        this.fetchTrelloBoards();
    }


    handleMoveLaneItemClick =(event, index, selected) => {
        debugger;
        const objMoveCard = {
            key: this.props.data.key,
            token: this.data.token,
            cardId: this.state._searchCardId,
            laneId: '',
            sourceLaneId: this.state._searchCardBoardCurrentLaneId,
            position: "",
            employeeNo: this.state.employeeNo,
            samaccountName: this.state.samaccountName,
            MycardContent: {
                cardId: this.state._searchCardId,
                id:this.state._searchCardId,
                cardName: this.state._title,
                title: this.state._title,
                cardDesc: this.state._cardDesc,
                description: this.state._cardDesc,
                labels:(this.state.selectedSearchIndexObject !=='')?this.state.selectedSearchIndexObject.labels:[],
                members:this.state._currentCardMembers,
                position: 'top',
                dueDate: this.state._dueDate,
                closed: false,
                taskId: 0,
                laneId: this.state._searchCardBoardCurrentLaneId,
                key: this.props.data.key,
                token: this.props.data.token,
                worksiteId: this.state._searchCardBoardId
            }
        }
        this.setState({myCardData:objMoveCard})
        if (selected.name === 'Done' || selected.name === 'done') {
            objMoveCard.laneId = selected.id;
            this.showDialogContentCancel();
            this.moveCardToLane(objMoveCard);

        } else {
            objMoveCard.laneId = selected.id;
            this.showDialogContentCancel();
            this.moveCardToLane(objMoveCard)
        }
    };

    handleListItemClick = (event, index, selected) => {

        if(this.state.SearchAllTrelloCardsResultSet.length > 0){
            this.setState({
                _searchShowCardInfo:false,
                _searchErrorCardMessage:'....loading',
                _TaskTypeState:''
            })

        this.searchInitialCaptureData.cardDescript = selected.desc;
        this.searchInitialCaptureData.cardTitle = selected.name;
        this.searchInitialCaptureData.cardTaskType = (selected.idLabels.length > 0) ? selected.labels[0].name : '';
        this.searchInitialCaptureData.cardDueDate = (typeof selected.due !== 'undefined') ? moment(selected.due).format('YYYY-MM-DD[T]HH:mm') : '';

        //get board members
        this.CardsService.GetAllMembersPerBoard({ boardId: selected.idBoard, key: this.props.data.key, token: this.props.data.token }).then(res => {

            let getBoardMember = res.data
            //get tasktypes
            this.CardsService.GetAllCardLabels().then(labelResponse => {

                const getLabelInfo = labelResponse.data;
                debugger;

                let storeMemberId = []
                for (let k = 0; k < selected.idMembers.length; k++) { storeMemberId.push({ id: selected.idMembers[k] }); }

                this.setState({
                    selectedSearchIndex: index,
                    selectedSearchIndexObject: selected,
                    openCardInfoContent: selected,
                    _searchCardBoardId: selected.idBoard,
                    _searchCardBoardCurrentLaneId: selected.idList,
                    _CheckedOffice365: false, //Check if an event was create against this object
                    _cardDesc: selected.desc,
                    _CheckboxReadOnly: false,//Check if an event was create against this object
                    _dueDate: (typeof selected.due !== 'undefined') ? moment(selected.due).format('YYYY-MM-DD[T]HH:mm') : '',
                    _startDate: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
                    _HideShowOffice365Date: 'none',
                    _title: selected.name,
                    _TaskData: getLabelInfo,
                    _taskId: selected.id,
                    _TaskTypeState: (selected.idLabels.length > 0) ? selected.labels[0].name : 'Select Task Type',
                    _currentBoardMembers: getBoardMember,
                    _currentCardMembers: storeMemberId,
                    _searchCardId:selected.id,
                    _searchShowCardInfo:true,
                    _searchSaveBtnStatus:true,
                    _searchLaneCardValue:{id:selected.idList,name:''},
                    _searchCardBoardName: 'Update Task'
                });

                this.CardsService.GetAllLanesPerboard({ boardId: selected.idBoard, key: this.props.data.key, token: this.props.data.token }).then(
                    response => {
                        if (response.data !== null ) {
                            this.setState({
                                _searchGetAllLanesPerboard: response.data,
                            })
                        }
                    }, error => {
                    this.setState({
                        _searchGetAllLanesPerboard: [],
                    });
                    });
                this.handleTaskTitle(selected.idBoard);


            }, labelError => {
                debugger;

            });
        }, error => {
            debugger;
        });

        this.CardsService.CheckCardEventRecord({ cardId: selected.id }).then(
            response => {

                if (response.data.icalUid !== null || response.data.eventId !== null || response.data.eventStartDate !== null) {
                    this.setState({
                        _HideShowOffice365Date: 'block',
                        _startDate: response.data.eventStartDate,
                        _searchEventId: response.data.eventId,
                        _eventSearchIculCalId: response.data.icalUid,
                        _CheckedOffice365: true, //Check if an event was create against this object _cardDesc: selected.desc,
                        _CheckboxReadOnly: true,//Check if an event was create against this object
                    })
                }


            }, error => {
                debugger;


            });
        }

    }




    /*FETCHES TRELLO BOARD NAVIGATION*/
    fetchTrelloBoards = () => {

        this.boardsService
            .GetTrelloBoardNavigation(this.props.data)
            .then(res => res.data)
            .then(
                response => {
                    console.log('TRELLO BOARDS ----->' + JSON.stringify(response));
                    let metro = [],organisationList =[], boardList = [];
                    for (let i = 0; i < response.length; i++) {
                        if (response[i].name !== 'Technical Support') {
                            if(typeof response[i].idOrganization !== 'undefined'){
                                organisationList.push({'employerId':response[i].idOrganization});
                            }
                            boardList.push({'boardNameId':response[i].id, 'boardName':response[i].name,'employerId':response[i].idOrganization});
                            metro.push(response[i]) }
                    }
                    boardList.push({'employerInfo':organisationList.filter((v,i,a)=>a.findIndex(t=>(t.employerId === v.employerId))===i)})
                    this.data.ObjectResponse = metro;
                    this.setState({_searchBoardsInfo:metro,
                        boardEmployerID: organisationList.filter((v,i,a)=>a.findIndex(t=>(t.employerId === v.employerId))===i),
                    userBoardList:boardList});
                    this.fetchTrelloCardsPerLane(metro, metro[0].id);
                    this.CardsService.
                        getAllStoredEmployers().then(res => {
                            const result = res.data;
                            debugger;
                            let i = 0;
                            let userEmployersBoardList = [{ 'employer_index': i, "employer_id": "0", "created_by": "0", "is_active": true, "created_at": "0", "employer_display_name": "Default" }];
                            let organisationList = [];
                            if (result.length > 0) {
                                for (let index = 0; index < result.length; index++) {
                                    const element = result[index];
                                    for (let n = 0; n < metro.length; n++) {
                                        const org = metro[n];
                                        if(typeof org.idOrganization !== "undefined"){
                                            if(element.employer_id === org.idOrganization){

                                                organisationList.push({"employer_id": element.employer_id});
                                               // userEmployersBoardList.push({ 'employer_index': ++i, "employer_id": element.employer_id, "created_by": element.created_by, "is_active": element.is_active, "created_at": element.created_at, "employer_display_name": element.employer_display_name });
                                            }
                                        }
                                    }
                                }
                              const _organisationList = organisationList.filter((v,i,a)=>a.findIndex(t=>(t.employer_id === v.employer_id))===i);
                                for (let index = 0; index < result.length; index++) {
                                    const element = result[index];
                                    if(_organisationList.some(_organisation => _organisation.employer_id === element.employer_id))
                                       userEmployersBoardList.push({ 'employer_index': ++i, "employer_id": element.employer_id, "created_by": element.created_by, "is_active": element.is_active, "created_at": element.created_at, "employer_display_name": element.employer_display_name });
                                }
                                this.setState({
                                    userEmployersBoardList: userEmployersBoardList
                                });
                            }

                        }, error => {
                            console.log(error);
                        });
                },
                error => {
                    this.setState({
                        overlay: true,
                        isLoaded: true,
                        error
                    });
                }
            );
    }


    /*FETCHES TRELLO BASED ON SEARCH QUERY*/
    SearchAllTrelloCards = (User_data, e) => {

        debugger;
        let user_Search_data = {
            'key': User_data.key,
            'token': User_data.token,
            'query': this.state.searchValueQuery
        };
        this.searchInitialCaptureData.cardDescript = '';
        this.searchInitialCaptureData.cardTitle = '';
        this.searchInitialCaptureData.cardTaskType =  '';
        this.searchInitialCaptureData.cardDueDate = '';

        this.setState({
            openSearchDialog: true,
            selectedSearchIndex: 0,
            selectedSearchIndexObject: '',
            openCardInfoContent: null,
            _searchCardBoardId: '',
            _searchCardBoardCurrentLaneId: '',
            _startDate: '',
            _cardDesc: '',
            _dueDate: '',
            _title: '',
            _TaskData: [],
            _taskId: '',
            _TaskTypeState: '',
            _currentBoardMembers: [],
            _currentCardMembers: [],
            _searchCardId:'',
            _searchEventId: '',
            _eventSearchIculCalId: '',
            _isLoaded:true
        })

        this.CardsService
            .SearchAllTrelloCards(user_Search_data)
            .then(res => res.data)
            .then(
                response => {

                    console.log('SEARCH TRELLO ----->' + JSON.stringify(response));
                    debugger;

                    let metro = [];
                    for (let i = 0; i < response.cards.length; i++) {
                        metro.push(response.cards[i]);
                    }
                    this.setState({
                        SearchAllTrelloCardsResultSet: metro,
                        _isLoaded:(metro.length > 0)?false:true,
                        _searchErrorCardMessage:(metro.length > 0)?'Please select relevent item from the listed':'Sorry, we were unable to find your search critiera'
                    });

                },
                error => {
                    this.setState({
                        searchValueQuery: '',
                        openSearchDialog: false,
                        isLoaded: true,
                        _isLoaded:false,
                        error
                    });
                }
            );
    }

    /*Search: Edit and Submit update */
    searchHandleSubmitData = (event) => {
        debugger;
        let taskId;
        //let inputVal = document.getElementById('cardDesc').value;
        event.preventDefault();
        const data = new FormData(event.target);
        if (this.state._taskId == "") {
            taskId = "0"
        } else {
            taskId = this.state._taskId;
        }

        let eventId, icalUid;

        if (this.state._searchEventId !== '' && this.state._eventSearchIculCalId) {
            eventId = this.state._searchEventId;
            icalUid = this.state._eventSearchIculCalId;
        } else {
            eventId = "";
        }

        const CardCalendarData = {
            calendarEvent: this.state._CheckedOffice365,
            attendeeList: [],
            bodyContent: data.get('cardDesc'),
            calendarLocationName: "",
            cardId: this.state._searchCardId,
            eventId: this.state._searchEventId,
            icalUid: this.state._eventSearchIculCalId,
            startDate: data.get('startDate'),
            endDate: data.get('dueDate'),
            subject: data.get('subject'),
            employeeNo: this.state.employeeNo,
            samaccountName: this.state.samaccountName,
            cardDTO: {
                cardDesc: data.get('cardDesc'),
                cardId: this.state._searchCardId,
                cardName: data.get('subject'),
                dueDate: data.get('dueDate'),
                key: this.props.data.key,
                taskId: parseInt(taskId),
                token: this.props.data.token

            },
            body: {
                content: data.get('cardDesc'),
                contentType: 'HTML'
            }
        }
        let label;
        if (this.state._TaskTypeState!== null) {
            label = this.state._TaskTypeState;
        } else {
            label = ""
        }

        if ((this.state._CheckedOffice365 === false) && (data.get('dueDate').trim() === this.searchInitialCaptureData.cardDueDate.trim())
            && (data.get('cardDesc').trim() === this.searchInitialCaptureData.cardDescript.trim()) &&
            (data.get('taskTypes').trim() === label.trim())/* && (JSON.stringify(this.state._currentCardMembers) === JSON.stringify(this.props.data.cardContent.members))*/) {

            toast.notify("Successfully updated", {
                duration: 3000
            });

        } else {
            this.setState({
                _isLoaded: true,
                _cardDesc: data.get('cardDesc'),
                _searchSaveBtnStatus:false
            });
            debugger;
            this.CardsService.createOffice365Event(CardCalendarData)
                .then(res => {
                    //console.log("Search CREATE EVENT 365" + JSON.stringify(res));
                    if (res.data.success === true) {
                        toast.notify(res.data.message, {
                            duration: 3000
                        });
                        this.setState({
                            _isLoaded: false,
                            _searchSaveBtnStatus:false
                        });
                        debugger;
                        /*this.props.parentCallbackRefreshBoard({
                            boardId: this.props.data.boardId,
                            HideShowOffice365Date: this.state._HideShowOffice365Date,
                            addCardToLaneFlag: false,
                            addCardToLaneByCardId: '',
                            addCardToLaneByLaneId: ''
                        });*/
                        //this.props.parentCallbackRefreshBoard(this.props.data.boardId, this.state.HideShowOffice365Date);
                    }
                }).catch(error => {
                    this.setState({
                        _isLoaded: false,
                        _searchSaveBtnStatus:true
                    });
                    toast.notify("Error updating card", {
                        "duration": 3000
                    });
                });
        }
    }
    renderAddRemoveMemberOptions = () => {
        return this.state._addRemoveMemberBoardMembers.map((dt, i) => {
            return (
                <MenuItem
                    key={i}
                    value={dt.fullName} title={dt.username} name={dt} data-my-value={JSON.stringify(dt)}>
                    {dt.fullName}
                </MenuItem>

            );
        });
    }
    _addRemoveMemberHandleChangeTaskTypes = (e) =>{
        debugger
       this.setState({_addRemoveMember:e.target.value});
        }
    /*Add/Remove: members */
    searchHandleSubmitAddRemoveMemberData = (event) => {
        debugger;
        this.setState({
            _isLoaded:true,
            _AddRemoveMemberSaveBtnStatus:false

        });
        event.preventDefault();

        if(this.state._addRemoveMember !=='Remove Member'){
            if(typeof this.state._addRemoveMemberBoardMembers.find(board => board.fullName === this.state._addRemoveMember) !== 'undefined'){
                let removeMember= this.state._addRemoveMemberBoardMembers.find(board => board.fullName === this.state._addRemoveMember);
                let removeMemberBody = {
                    boardId:this.state.selectedAddRemoveMemberIndexObject.id,
                    key:this.props.data.key,
                    token:this.props.data.token,
                    removeMemberId: removeMember.id
                };
                this.CardsService.removeMemberFromBoard(removeMemberBody).then(res =>{
                    debugger;
                    this.setState({
                        _isLoaded: false,
                        _searchSaveBtnStatus:true,
                        _AddRemoveMemberSaveBtnStatus:false,
                        _addRemoveMemberBoardMembers:res.data.members
                    });
                    toast.notify("Successfully removed member", {
                        "duration": 3000
                    });
                    this.boardsService
            .GetTrelloBoardNavigation(this.props.data)
            .then(res => res.data)
            .then(
                response => {
                    let metro = [];
                    for (let i = 0; i < response.length; i++) {
                        if (response[i].name !== 'Technical Support') { metro.push(response[i]) }
                    }
                    this.data.ObjectResponse = metro;
                    this.setState({_searchBoardsInfo:metro});
                },
                error => {
                    this.setState({
                        overlay: true,
                        isLoaded: true,
                        error
                    });
                }
            );

                },error =>{
                    debugger
                    this.setState({
                        _isLoaded: false,
                        _searchSaveBtnStatus:true,
                        _AddRemoveMemberSaveBtnStatus:true
                    });
                    toast.notify("Error removing member card", {
                        "duration": 3000
                    });

                });
            }
            if(this.state._addRemoveMemberText_add_emailItem.length > 0){
                this.setState({
                    _isLoaded:true,
                });
                let addUser ={
                    boardId:this.state.selectedAddRemoveMemberIndexObject.id,
                    key:this.props.data.key,
                    token:this.props.data.token,
                    userDetails:[]
                };

                for (let index = 0; index < this.state._addRemoveMemberText_add_emailItem.length; index++) {
                   debugger;
                    let userDatailPayload ={
                        email:this.state._addRemoveMemberText_add_emailItem[index],
                        userLdapUser:'',
                        userToken:'',
                        userType:'',
                        userkey:''
                    };
                    addUser.userDetails.push(userDatailPayload);

                }

                this.CardsService.addMemberToBoard(addUser).then(res =>{
                    debugger;
                    this.setState({
                        _isLoaded: false,
                        _searchSaveBtnStatus:true,
                        _AddRemoveMemberSaveBtnStatus:false,
                        _addRemoveMemberBoardMembers:res.data.members,
                        _addRemoveMemberText_add_emailItem:[]
                    });
                    toast.notify("Successfully Added member", {
                        "duration": 3000
                    });

                },error =>{
                    debugger;
                    this.setState({
                        _isLoaded: false,
                        _searchSaveBtnStatus:true,
                        _AddRemoveMemberSaveBtnStatus:true
                    });
                    toast.notify("Error updating members", {
                        "duration": 3000
                    });

                });

            }

        }else{
            if(this.state._addRemoveMemberText_add_emailItem.length > 0){
                this.setState({
                    _isLoaded:true,
                });
                let addUser ={
                    boardId:this.state.selectedAddRemoveMemberIndexObject.id,
                    key:this.props.data.key,
                    token:this.props.data.token,
                    userDetails:[]
                };

                for (let index = 0; index < this.state._addRemoveMemberText_add_emailItem.length; index++) {
                   debugger;
                    let userDatailPayload ={
                        email:this.state._addRemoveMemberText_add_emailItem[index],
                        userLdapUser:'',
                        userToken:'',
                        userType:'',
                        userkey:''
                    };
                    addUser.userDetails.push(userDatailPayload);

                }

                this.CardsService.addMemberToBoard(addUser).then(res =>{
                    debugger;
                    this.setState({
                        _isLoaded: false,
                        _searchSaveBtnStatus:true,
                        _AddRemoveMemberSaveBtnStatus:false,
                        _addRemoveMemberBoardMembers:res.data.members,
                        _addRemoveMemberText_add_emailItem:[]
                    });
                    toast.notify("Successfully adding member", {
                        "duration": 3000
                    });

                },error =>{
                    debugger;
                    this.setState({
                        _isLoaded: false,
                        _searchSaveBtnStatus:true,
                        _AddRemoveMemberSaveBtnStatus:true
                    });
                    toast.notify("Error adding  member", {
                        "duration": 3000
                    });

                });

            }

        }



    }

    GetAllMembersPerBoards = (MemberData) => {


        console.log(" ALL PROP DATA INFORMATION" + JSON.stringify(MemberData));
        this.CardsService
            .GetAllMembersPerBoard(MemberData)
            .then(res => {

                this.setState({ members: res.data });

                //    this.allItems = res.data ;

            })
            .catch(error => {
                toast.notify("Error retrieving data", {
                    duration: 3000
                });
            });

    }

    handleChange = (event, value) => {

        this.fetchTrelloCardsPerLane('', value);
    };
    handleMoveLaneClick = (event, value) =>{

        //this.handleMoveLaneItemClick(event, 0, value);
        debugger;
        this.setState({
            anchorEl:event.currentTarget
        });
    }

    handleMoveLaneClickClose = () => {
        this.setState({
            anchorEl:null
        });
      };


    handleSearchChange = (event) => {

        this.setState({
            searchValueQuery: event.target.value

        });
    };

    handleTaskTitle = (boardId) => {
        let title = this.state._searchCardBoardName;
        if(typeof this.data.ObjectResponse.find(board => board.id === boardId) !== 'undefined'){
            title = "Update Task From " + this.data.ObjectResponse.find(board => board.id === boardId).name;
            this.setState({_searchCardBoardName:title});
        }else{
           //call the service pass boardId as arg
           this.CardsService.GetBoardInfoDetails({ boardId: boardId, key: this.props.data.key, token: this.props.data.token }).then(
            response => {
                if (response.data !== null ) {
                    title = "Update Task From " + response.data.name;
                    this.setState({_searchCardBoardName:title});
                }
            }, error => {
                this.setState({_searchCardBoardName:""});
            });
        }

    };




    fetchTrelloCardsPerLane = (carry, boardId) => {

        this.setState({
            cards: null,
            overlay: true, tabClick: false, openCard: false
        });
        this.CardsService
            .GetAllTrelloCardsPerLane(this.data, boardId)
            .then(res => {


                let userBoards;
                userBoards = (carry !== '') ? carry : this.data.ObjectResponse.filter(u => u.name !== 'Technical Support');

                this.setState({
                    cards: res.data,
                    overlay: false,
                    boards: userBoards,
                    value: boardId,
                    openCard: false,
                    tabClick: true,
                    openOutcome: false,
                });
                const memberData = {
                    boardId: this.state.value,
                    token: this.props.data.token,
                    key: this.props.data.key
                }
                this.GetAllMembersPerBoards(memberData);
                if (this.state.addCardToLaneFlag) {

                    for (let b = 0; b < res.data.lanes.length; b++) {

                        for (let c = 0; c < res.data.lanes[b].cards.length; c++) {

                            if ((this.state.addCardToLaneByCardId !== '' && this.state.addCardToLaneByCardId === res.data.lanes[b].cards[c].id) &&
                                (this.state.addCardToLaneByLaneId !== '' && this.state.addCardToLaneByLaneId === res.data.lanes[b].id)
                            ) {

                                this.setState({
                                    openCard: true,
                                    openCardInfo: {
                                        cardId: this.state.addCardToLaneByCardId,
                                        cardDesc: res.data.lanes[b].cards[c].description,
                                        cardContent: res.data.lanes[b].cards[c]
                                    }
                                });

                            }

                        }

                    }
                }
            },
                error => {
                    this.setState({
                        overlay: false,
                        error
                    });
                }
            );
    }


    callbackFuncCloseBoard = (data) => {



        const objMoveCard = {
            key: data.cardContent.key,
            token: data.cardContent.token,
            cardId: data.cardContent.cardId,
            laneId: data.cardContent.sourceLaneId,
            sourceLaneId: data.cardContent.sourceLaneId,
            position: "",
            employeeNo: data.cardContent.employeeNo,
            samaccountName: data.cardContent.samaccountName

        }

        this.setState({ "overlay": true });
        this.CardsService
            .MoveCardToLane(objMoveCard)
            .then(res => {
                console.log(JSON.stringify(res.data));
                toast.notify('Card has been moved back to ' + res.data.targetNameLane + '.', {
                    duration: 4000
                });

                this.fetchTrelloCardsPerLane('', data.boardId);


            })
            .catch(error => {

                toast.notify("Error moving card", {
                    duration: 1200
                });
                this.setState({ "overlay": false });
            });

    }

    state = { boardId: "" }
    callbackFuncRefreshBoard = (backFuncRefreshBoard) => {
        if (backFuncRefreshBoard.HideShowOffice365Date !== null) {

            this.setState({
                boardId: backFuncRefreshBoard.boardId,
                HideShowOffice365Date: backFuncRefreshBoard.HideShowOffice365Date,
                openOutcome: false,
                addCardToLaneFlag: backFuncRefreshBoard.addCardToLaneFlag,
                addCardToLaneByCardId: backFuncRefreshBoard.addCardToLaneByCardId,
                addCardToLaneByLaneId: backFuncRefreshBoard.addCardToLaneByLaneId
            });


        } else {
            this.setState({
                boardId: backFuncRefreshBoard.boardId,
                openOutcome: false,
                addCardToLaneFlag: backFuncRefreshBoard.addCardToLaneFlag,
                addCardToLaneByCardId: backFuncRefreshBoard.addCardToLaneByCardId,
                addCardToLaneByLaneId: backFuncRefreshBoard.addCardToLaneByLaneId
            });

        }

        this.fetchTrelloCardsPerLane('', backFuncRefreshBoard.boardId);
    }

    callbackFuncParentCloseDialogue = (DialogStatus) => {

        this.setState({ tabClick: DialogStatus })

    }

    searchHandleStartDateChange = (event) => {
        this.setState({ _startDate: event.target.value,_searchSaveBtnStatus:true });
    }

    searchHandleDueDateChange = (event) => {
        this.setState({ _dueDate: event.target.value,_searchSaveBtnStatus:true });
    }

    searchHandleChangeTaskTypes = (event) => {
        var inputDesc = event.view.document.getElementById('cardDesc').value;
        var inputDueDate = event.view.document.getElementById('dueDate').value;
        var inputStartDate = event.view.document.getElementById('startDate').value;
        var inputSubject = event.view.document.getElementById('subject').value;
        var obj = JSON.parse(event.currentTarget.dataset.myValue);

        debugger;
        this.setState({
            _taskId: obj.id,
            _TaskTypeState: event.target.value,
            _cardDesc: inputDesc,
            _dueDate: inputDueDate,
            _startDate: inputStartDate,
            _title: inputSubject,
            _searchSaveBtnStatus:true

        })
    }

    searchRenderTaskTypesOptions = () => {
        return this.state._TaskData.map((dt, i) => {
            return (
                <MenuItem
                    key={i}
                    value={dt.name} title={dt.description} name={dt} data-my-value={JSON.stringify(dt)}>
                    <LabelIcon style={{ color: dt.color }} />
                    &nbsp; {dt.name}
                </MenuItem>

            );
        });
    }

    searchHandleAddSubject = (event) => {
        this.setState({ _title: event.target.value, _searchSaveBtnStatus:true });
    }



    searchHandleAddMember = (e, chipToAddMember) => {
        let objAddCard = {
            key: 'ObjCardToAdd.key',
            token: 'ObjCardToAdd.token',
            idMember: 'ObjCardToAdd.idMember',
            cardId: 'ObjCardToAdd.cardId',
            samaccountName: 'ObjCardToAdd.employeeNum',
            employeeNum: 'ObjCardToAdd.employeeNo'
        }

        let AllBoardMembers = this.state._currentBoardMembers;
        if (e.target.innerText === '') return;
        for (var i = 0; i < AllBoardMembers.length; i++) {
            if (e.target.innerText === AllBoardMembers[i].fullName) {
                objAddCard.key = this.props.data.key;
                objAddCard.token = this.props.data.token;
                objAddCard.idMember = AllBoardMembers[i].id;
                objAddCard.cardId = this.state._searchCardId;
                objAddCard.employeeNum = this.state.employeeNo;
                objAddCard.samaccountName = this.state.samaccountName;
                break;
            }
        }
        debugger;
        if (objAddCard.key === 'ObjCardToAdd.key' || objAddCard.idMember === 'ObjCardToAdd.idMember' || objAddCard.cardId === 'ObjCardToAdd.cardId' || this.state._currentCardMembers.find(function (element) {
            return element.id === objAddCard.idMember;
        })) {
            this.setState({ _isLoaded: true });
            this.CardsService.retrieveCardMembers({
                cardId: this.state._searchCardId,
                key: this.props.data.key,
                token: this.props.data.token
            }).then(res => {
                this.setState({ _currentCardMembers: res.data,_isLoaded: false });
            }).catch(error => {
                alert('error' + error);
            });
        } else {
            toast.notify('Adding member/s currently in progress', {
                duration: 3000
            });
            this.setState({ _isLoaded: true });
            this.CardsService
                .AddMemberToCard(objAddCard)
                .then(res => {
                    this.CardsService.retrieveCardMembers({
                        cardId: this.state._searchCardId,
                        key: this.props.data.key,
                        token: this.props.data.token
                    }).then(res => {
                        this.setState({ _currentCardMembers: res.data,_isLoaded: false });
                    }).catch(error => {
                        alert('error' + error);
                    });
                    //console.log(JSON.stringify(res.data[0].fullName))
                    toast.notify(res.data.message, {
                        duration: 3000
                    });
                })
                .catch(error => {
                    alert('error' + error);
                });
        }
    };

    searchHandleDeleteMember = (e, chipToDelete, allChips) => {
        debugger;
        let objDeleteCard = {
            cardId: this.state._searchCardId,
            idMember: chipToDelete.id,
            key: this.props.data.key,
            token: this.props.data.token,
            employeeNo: this.state.employeeNo,
            samaccountName: this.state.samaccountName
        };
        this.setState({_isLoaded: true});
        toast.notify('Deleting member/s current in progress...', {
            duration: 3000
        });
        this.CardsService
            .removeMemberAssignedCard(objDeleteCard)
            .then(res => {
                //console.log(JSON.stringify(res.data[0].fullName))
                for (var i = 0; i < allChips.length; i++) {
                    if (allChips[i].id == chipToDelete.id) {
                        allChips.splice(i, 1);
                    }
                }
                toast.notify(res.data.message, {
                    duration: 3000
                });
                this.CardsService.retrieveCardMembers({
                    cardId: this.state._searchCardId,
                    key: this.props.data.key,
                    token: this.props.data.token
                }).then(res => {
                    this.setState({ _currentCardMembers: res.data,_isLoaded: false });
                })
                    .catch(error => {
                        toast.notify(error, {
                            duration: 3000
                        });
                    });
            })
            .catch(error => {
                toast.notify(error + " Member issue", {
                    duration: 3000
                });
            });


    };

    componentWillUnmount() {
        ValidatorForm.removeValidationRule('_isEndDateGreaterStartDateCheck');
        ValidatorForm.removeValidationRule('_isTaskTypeSelected');
        ValidatorForm.removeValidationRule('_isDueDateCaptured');
        ValidatorForm.removeValidationRule('_isStartDateCaptured');
        ValidatorForm.removeValidationRule('_isSubject');
        debugger;
        this.setState({
            OpenDialog: false
        });
    }

    //Default member
    searchDefaultValueAutoComplete = () => {
        debugger
        var arr = [];
        for (var item = 0; item < this.state._currentBoardMembers.length; ++item) {
            for (var i = 0; i < this.state._currentCardMembers.length; ++i) {
                if (this.state._currentBoardMembers[item].id === this.state._currentCardMembers[i].id) {
                    arr.push(this.state._currentBoardMembers[item]);

                }
            }
        }
        return arr;
    }
    handleSearchSubmit = (event) => {

        if(this.state.searchValueQuery !== ''){
            this.SearchAllTrelloCards(this.data, event);
        }


    };
    handleAddRemoveMemberSubmit = (event) => {
        debugger;
        //this.SearchAllTrelloCards(this.data, event)
        this.setState({
            openAddRemoveDialog:true,
            _addRemoveMemberErrorCardMessage:(this.state._searchBoardsInfo.length > 0)?'Please select relevent item from the listed':'Sorry, we were unable to find any boards assigned to you'
        });
    };

    handleAddRemoveMemberListItemClick = (event, index, selected) => {

        if(this.state._searchBoardsInfo.length > 0){
            this.setState({
                _addRemoveMemberShowCardInfo:false,
                _addRemoveMemberErrorCardMessage:'....loading',

            })



        //get board members
        this.CardsService.GetAllMembersPerBoard({ boardId: selected.id, key: this.props.data.key, token: this.props.data.token }).then(res => {

            let getBoardMember = res.data;
            debugger;
            this.setState({
                    _addRemoveMemberShowCardInfo:true,
                    _AddRemoveMemberSaveBtnStatus:true,
                    selectedAddRemoveMemberIndex: index,
                    selectedAddRemoveMemberIndexObject: selected,
                    _addRemoveMemberBoardName:selected.name,
                    _addRemoveMemberBoardMembers:getBoardMember,
                    _addRemoveMember: 'Remove Member'



            });
            this.handleAddRemoveMembersTitle(selected.id);

        }, error => {
            debugger;
            this.setState({
                _addRemoveMemberBoardMembers:[],
                _addRemoveMemberText_add_emailItem: []
            });
        });


        }

    }

     handleAddRemoveMembersTitle = (boardId) => {
        let title = this.state._addRemoveMemberBoardName;
        debugger;
        if(typeof this.state._searchBoardsInfo.find(board => board.id === boardId) !== 'undefined'){
            title = "Update Worksite " + this.state._searchBoardsInfo.find(board => board.id === boardId).name;
            this.setState({_addRemoveMemberBoardName:title});
        }else{
           //call the service pass boardId as arg
           this.CardsService.GetBoardInfoDetails({ boardId: boardId, key: this.props.data.key, token: this.props.data.token }).then(
            response => {
                if (response.data !== null ) {
                    title = "Update Worksite " + response.data.name;
                    this.setState({_addRemoveMemberBoardName:title});
                }
            }, error => {
                this.setState({_addRemoveMemberBoardName:""});
            });
        }

    };





    render() {
        console.log("EMPLOYEE INFO" + this.state.employeeNo + " " + this.state.samaccountName);
        console.log("BOOLEAN LOGGER --->" + this.state.overlay);
        const { classes } = this.props;

        /*MOVES CARD TO LANE*/

        this.moveCardToLane = (objMoveCard) => {
            this.setState({ "overlay": true });
            this.CardsService
                .MoveCardToLane(objMoveCard)
                .then(res => {

                    console.log(JSON.stringify(res.data));
                    if (res.data.targetNameLane === "Done") {
                        this.setState({
                            "openOutcome": true,
                            "isActive": true,
                            "overlay": false
                        });
                    } else {
                        this.fetchTrelloCardsPerLane('', this.state.value);
                        this.setState({
                            overlay: true
                        });
                    }
                })
                .catch(error => {

                    if (objMoveCard.position !== "") {

                        toast.notify("Error moving card", {
                            duration: 3000
                        });
                    }
                    this.setState({
                        overlay: false
                    });
                });
        }



        /*ADD CARD TO LANE*/
        this.addCardToLane = (objAddCard) => {
            this.setState({
                overlay: true
            });
            this.CardsService
                .addCardToLane(objAddCard)
                .then(res => {
                    toast.notify(res.data.message, {
                        duration: 3000
                    });
                    console.log("Card Successfully Added");
                    console.log('MY SUCCESS DATA' + JSON.stringify(res.data));
                    this.setState({ 'cardId': res.data.cardId })
                    this.boardsService
                        .GetMemberInformation({
                            key: this.props.data.key,
                            token: this.props.data.token
                        })
                        .then(res => {
                            console.log('MEMBER INFORMATION' + JSON.stringify(res.data));

                            const addMember = {
                                key: this.props.data.key,
                                token: this.props.data.token,
                                cardId: this.state.cardId,
                                employeeNum: this.state.employeeNo,
                                idMember: res.data.id,
                                samaccountName: this.state.samaccountName

                            };
                            console.log('ADD MEMBER OBJECT ------- ' + JSON.stringify(addMember))
                            this.CardsService.AddMemberToCard(addMember).then(res => res.data).then(
                                response => {


                                    this.setState({ addCardToLaneFlag: true, addCardToLaneByCardId: this.state.cardId, addCardToLaneByLaneId: objAddCard.laneId });

                                    this.fetchTrelloCardsPerLane('', this.state.value);

                                },
                                error => {
                                    this.setState({
                                        overlay: false
                                    });

                                    toast.notify("Error retrieving data" + error, {
                                        duration: 3000
                                    });
                                }
                            );


                        });
                    /*this.setState({addCardToLaneFlag:true, addCardToLaneByCardId:this.state.cardId,addCardToLaneByLaneId:objAddCard.laneId});

                    this.fetchTrelloCardsPerLane('',this.state.value);*/


                })
                .catch(error => {
                    toast.notify("Error adding card", {
                        duration: 3000
                    });
                });


        }




        /*DELETE CARD FROM LANE*/
        this.DeleteCard = (objDeleteCard, CalData) => {
            this.CardsService
                .DeleteCard(objDeleteCard)
                .then(res => {

                    toast.notify(res.data.message, {
                        duration: 3000
                    });
                    this.fetchTrelloCardsPerLane('', this.state.value);
                    debugger;
                    if (CalData.eventId !== "") {

                        const objUpdateEventData = {
                            eventId: CalData.eventId,
                            eventICal_id: CalData.icalUid,
                            subject: '',
                            bodyContent: '',
                            startDate: '',
                            endDate: '',
                            calendarLocationName: '',
                            attendeeList: [],
                            body: null

                        };

                        this.CardsService.deleteOffice365Event(objUpdateEventData).then(res => res.data).then(
                            response => {
                                toast.notify(response.message, {
                                    duration: 3000
                                });
                            });
                        //   this.notifier.openSnackbar({ message: 'Card successfully deleted' });


                        console.log("Delete Card Successfully ");
                    }
                })
                .catch(error => {
                    toast.notify("Error deleting card", {
                        duration: 3000
                    });
                });
        }

        const handleEmployersClickListItem = (event) => {
            this.setState({
              anchorElEmployers: event.currentTarget
            });
          };

          const handleEmployersClose = () => {
            this.setState({
              anchorElEmployers: null
            });
          };

          const handleEmployersMenuItemClick = (event, index,employer) => {
            debugger;
            this.setState({
              anchorElEmployers: null,
              selectedEmployersIndex: index,
              overlay: false,
            });
            if(employer.employer_id === '0'){
                this.fetchTrelloBoards();
            }else{
                this.CardsService
            .getAllBoardsOrganizationEmployerDetails({employerId:employer.employer_id, key: this.props.data.key, token: this.props.data.token})
            .then(res => res.data)
            .then(
                response => {

                    console.log('TRELLO BOARDS ----->' + JSON.stringify(response));

                    let metro = [],organisationList =[], boardList = [];
                    if(response.length > 0){
                        for (let i = 0; i < response.length; i++) {
                            if (response[i].name !== 'Technical Support') {
                                if(typeof response[i].idOrganization !== 'undefined'){
                                    organisationList.push({'employerId':response[i].idOrganization});
                                }
                                boardList.push({'boardNameId':response[i].id, 'boardName':response[i].name,'employerId':response[i].idOrganization});
                                metro.push(response[i]) }
                        }
                        boardList.push({'employerInfo':organisationList.filter((v,i,a)=>a.findIndex(t=>(t.employerId === v.employerId))===i)})
                        this.data.ObjectResponse = metro;
                        this.setState({_searchBoardsInfo:metro,
                            boardEmployerID: organisationList.filter((v,i,a)=>a.findIndex(t=>(t.employerId === v.employerId))===i),
                        userBoardList:boardList});
                        this.fetchTrelloCardsPerLane(metro, metro[0].id);

                    }else{
                        this.boardsService
            .GetTrelloBoardNavigation(this.props.data)
            .then(res => res.data)
            .then(
                response => {
                    console.log('TRELLO BOARDS ----->' + JSON.stringify(response));
                    let metro = [],organisationList =[], boardList = [];
                    for (let i = 0; i < response.length; i++) {
                        if (response[i].name !== 'Technical Support' && typeof response[i].idOrganization !== 'undefined' && response[i].idOrganization === employer.employer_id) {

                            boardList.push({'boardNameId':response[i].id, 'boardName':response[i].name,'employerId':response[i].idOrganization});
                            metro.push(response[i]) }
                    }
                    boardList.push({'employerInfo':organisationList.filter((v,i,a)=>a.findIndex(t=>(t.employerId === v.employerId))===i)})
                    this.data.ObjectResponse = metro;
                    this.setState({_searchBoardsInfo:metro,
                        boardEmployerID: organisationList.filter((v,i,a)=>a.findIndex(t=>(t.employerId === v.employerId))===i),
                    userBoardList:boardList});
                    this.fetchTrelloCardsPerLane(metro, metro[0].id);
                },
                error => {
                    this.setState({
                        overlay: true,
                        isLoaded: true,
                        error
                    });
                }
            );
                    }

                },
                error => {
                    this.setState({
                        overlay: true,
                        isLoaded: true,
                        error
                    });
                }
            );

            }

          };




        /*DRAG CARD BETWEEN  LANES*/
        const handleDragEnd = (data, cardId, sourceLaneId, targetLaneId) => {


            let OutcomesCardContent;


            const objMoveCard = {
                key: this.props.data.key,
                token: this.data.token,
                // boardId: ,
                cardId: cardId,
                laneId: targetLaneId,
                sourceLaneId: sourceLaneId,
                position: "",
                employeeNo: this.state.employeeNo,
                samaccountName: this.state.samaccountName,
                MycardContent: ''
            }



            if (data.lanes.length > 0) {
                data.lanes.forEach(function (entry) {
                    if (entry.cards.length >= 1) {
                        entry.cards.forEach(function (info) {
                            if (cardId === info.id) {
                                console.log('CARD INFOMATION ------ ' + JSON.stringify(info));
                                objMoveCard.MycardContent = info
                            }
                        });
                    }
                });
            }


            this.setState({ "myCardData": objMoveCard });
            this.moveCardToLane(objMoveCard);

        }

        const onCardAdd = (card, laneId) => {
            debugger;
            const objAddCard = {
                key: this.props.data.key,
                token: this.data.token,
                laneId: laneId,
                cardId: card.id,
                cardName: card.title,
                cardDesc: card.description,
                employeeNo: this.state.employeeNo,
                samaccountName: this.state.samaccountName
            }

            console.log('CARD ID TESTING ' + objAddCard);
            this.addCardToLane(objAddCard);

            //this.fetchTrelloCardsPerLane = ('',this.props.data.boardId)
        }

        const onCardDelete = (card, laneId) => {


            confirmAlert({
                closeOnClickOutside: false,
                customUI: ({ onClose }) => {
                    return (
                        <div className='react-confirm-alert-body'>
                            <h1>Delete Record</h1>
                            <p>Are you want to delete this task.</p>

                            <button className={'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary'} onClick={() => {
                                handleClickDelete(data, card, laneId)
                                onClose()
                            }}>Yes</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button className={'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary'} onClick={() => {
                                handleClickDoNotDelete(card, laneId)
                                onClose()
                            }}>No</button>
                        </div>
                    )
                }
            })


        }

        const handleClickDoNotDelete = (card, laneId) => {

            this.fetchTrelloCardsPerLane('', this.state.value);
        }


        const handleClickDelete = (data, card, laneId) => {
            const objDeleteCard = {
                cardId: card,
                closed: true,
                key: this.props.data.key,
                laneId: laneId,
                token: this.data.token,
                employeeNo: this.state.employeeNo,
                samaccountName: this.state.samaccountName
            }

            console.log(' DELETE CARD PAYLOAD ----> ' + JSON.stringify(objDeleteCard));

            let CalData = {
                "eventId": "",
                "icalUid": ""
            };



            if (data.lanes.length > 0) {

                data.lanes.forEach(function (entry) {
                    if (entry.cards.length > 1) {
                        entry.cards.forEach(function (info) {
                            if (info.calendarEvents !== null) {
                                if (card === info.id) {
                                    CalData = {
                                        "eventId": info.calendarEvents.eventId,
                                        "icalUid": info.calendarEvents.icalUid
                                    };
                                }
                            }

                        });
                    }
                });
            }

            this.DeleteCard(objDeleteCard, CalData);
        }

        const displayOutcomesDialog = () => {


            const Data = {
                key: this.props.data.key,
                token: this.props.data.token,
                boardId: this.state.value,
                cardId: this.state.openCardInfo.cardId,
                cardDesc: this.state.openCardInfo.cardDesc,
                cardContent: this.state.myCardData,
                employeeNo: this.state.employeeNo,
                samaccountName: this.state.samaccountName,
            };


            if (this.state.openOutcome) {

                return <OutcomesDialogs data={Data} OutComesDialogState={this.state.openOutcome} parentCallbackRefreshBoard={this.callbackFuncRefreshBoard} parentCallCloseBoard={this.callbackFuncCloseBoard} />;
            }
        }

        const displayCustomizedDialog = () => {
            if (this.state.openCard) {

                if (this.state.openCardInfo.cardContent.calendarEvents !== null) {
                    const objCheckEventExist = {
                        eventId: this.state.openCardInfo.cardContent.calendarEvents.eventId,
                        eventICal_id: '',
                        subject: '',
                        bodyContent: '',
                        startDate: '',
                        endDate: '',
                        calendarLocationName: '',
                        attendeeList: [],
                        body: null
                    }



                    console.log('Check my object data ---> ' + JSON.stringify(objCheckEventExist));

                    this.CardsService.CheckOffice365EventExist(objCheckEventExist)
                        .then(res => {
                            console.log("Office 365 EVENT" + JSON.stringify(res));

                            console.log('STATUS : ' + res.data.eventIdExistOffice365)
                            if (res.data.eventIdExistOffice365 === false) {
                                console.log('Event does not exist on 365')
                                const objEventId = {
                                    eventId: this.state.openCardInfo.cardContent.calendarEvents.eventId,
                                }
                                this.CardsService.updateCalendarEventDates(objEventId).then(res => {
                                    console.log('Updated event to delete')
                                    this.state.openCardInfo.cardContent.eventId = null;
                                });
                            }

                        }).catch(error => {
                            this.setState({
                                "overlay": false,
                            });
                            console.log('error connecting to 365')
                        });

                }

                let dueDate
                if (this.state.openCardInfo.cardContent.calendarEvents !== null) {

                    dueDate = moment(this.state.openCardInfo.cardContent.calendarEvents.eventEndDate).format('YYYY-MM-DD[T]HH:mm')
                } else {
                    dueDate = moment(this.state.openCardInfo.cardContent.label).format('YYYY-MM-DD[T]HH:mm')
                }

                /*if(dueDate==="Invalid date")
              {
                 dueDate =  moment(Date.now()).format('YYYY-MM-DD[T]HH:mm')
              }
 */
                let keepData = {
                    key: '',
                    token: '',
                    boardId: '',
                    cardId: '',
                    cardDesc: '',
                    cardContent: '',
                    employeeNo: '',
                    samaccountName: '',
                    dueDate: ''
                },



                    customizedDialogState = this.state.openCard;

                keepData = {
                    key: this.props.data.key,
                    token: this.props.data.token,
                    boardId: this.state.value,
                    cardId: this.state.openCardInfo.cardId,
                    cardDesc: this.state.openCardInfo.cardDesc,
                    cardContent: this.state.openCardInfo.cardContent,
                    employeeNo: this.state.employeeNo,
                    samaccountName: this.state.samaccountName,
                    dueDate: dueDate
                };

                if ((this.state.openCardInfo.cardId === null || this.state.openCardInfo.cardId === '') || this.state.openCardInfo.cardContent === null || this.state.openCardInfo.cardContent === '') {
                    keepData.cardId = 'Error consuming trello card id';
                    keepData.cardContent = {
                        title: 'System Error consuming trello card Title', description: 'System Error consuming trello card description, please log a ticket!'

                    }
                }

                console.log('State data' + JSON.stringify(keepData));
                return <CustomizedDialogs data={keepData} customizedDialogState={customizedDialogState} AllboardMembers={this.state.members} parentCallbackRefreshBoard={this.callbackFuncRefreshBoard} parentCloseDialogue={this.callbackFuncParentCloseDialogue} />;
            } else {
                return '';
            }

        }

        const onCardClick = (laneInfo, cardId) => {

            /*const memberData = {
                boardId: this.state.value,
                token: this.props.data.token,
                key: this.props.data.key
            }

            this.GetAllMembersPerBoards(memberData);*/


            this.setState({ openCard: false, tabClick: true });
            let openCardInfoHolder = { cardId: '', cardDesc: '', cardContent: '', openCard: false };
            if (laneInfo.lanes.length > 0) {
                laneInfo.lanes.forEach(function (entry) {
                    if (entry.cards.length === 1) {
                        console.log('Size is 1' + entry);
                        if (cardId === entry.cards[0].id) {
                            openCardInfoHolder.cardId = entry.cards[0].id;
                            openCardInfoHolder.cardDesc = entry.cards[0].description;
                            openCardInfoHolder.cardContent = entry.cards[0];
                            openCardInfoHolder.openCard = true;
                            openCardInfoHolder.openCard = true;
                        }
                    } else if (entry.cards.length > 1) {
                        entry.cards.forEach(function (info) {
                            if (cardId === info.id) {
                                openCardInfoHolder.cardId = info.id;
                                openCardInfoHolder.cardDesc = info.description;
                                openCardInfoHolder.cardContent = info;
                                openCardInfoHolder.openCard = true;
                            }
                        });
                    }
                });
                if (openCardInfoHolder.cardId !== '' && openCardInfoHolder.cardContent !== '') {
                    this.setState({
                        openCard: openCardInfoHolder.openCard,
                        openCardInfo: {
                            cardId: openCardInfoHolder.cardId,
                            cardDesc: openCardInfoHolder.cardDesc,
                            cardContent: openCardInfoHolder.cardContent
                        }
                    });
                }
            }

        }

        const renderSearchResultFilter = () => {
            debugger;
            let res = this.state.SearchAllTrelloCardsResultSet;
            if (res.length === 0) {
                return <List component="nav" aria-label="main mailbox folders">
                    <ListItem
                        button
                        selected={this.state.selectedSearchIndex === 0}
                        onClick={event => this.handleListItemClick(event, 0, '')}>
                        <ListItemAvatar>
                            <Avatar alt="avatar">a</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Sorry nothing was found"
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        color="textPrimary">
                                         </Typography>

                                </React.Fragment>
                            }
                        />
                    </ListItem>
                </List>

            } else {
                return (
                    <List component="nav" aria-label="main mailbox folders">
                        {res.map((sectionId, index) => {
                            return (
                                <ListItem button
                                    selected={this.state.selectedSearchIndex === index}
                                    onClick={event => this.handleListItemClick(event, index, sectionId)}
                                    key={index} >
                                    <ListItemAvatar >
                                    <Tooltip title={sectionId.name}>
                                        <Avatar alt="avatar" className={classes.avatarListCklr}>{sectionId.name.charAt(0)}</Avatar>
                                        </Tooltip>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<React.Fragment>
                                            <Typography
                                                color="textPrimary"
                                                style={{ fontSize: "15px" }} >
                                                {sectionId.name}
                                            </Typography>
                                        </React.Fragment>}
                                        secondary={
                                            <React.Fragment>
                                                {
                                                    sectionId.desc ? (
                                                        <React.Fragment>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                //className={classes.inline}
                                                                color="textPrimary">
                                                                Description -
                                      </Typography>
                                                            {" " + sectionId.desc}
                                                        </React.Fragment>
                                                    ) : (
                                                            <React.Fragment>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    //className={classes.inline}
                                                                    color="textPrimary">
                                                                    Description -
                                      </Typography>
                                                                {" " + "None"}
                                                            </React.Fragment>
                                                        )
                                                }
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            );

                        })}
                    </List>
                );
            }

        };
        const _addRemoveMemberCardDetailClickedItem = () =>{
            return (
                <Card style={(this.state._addRemoveMemberShowCardInfo) ? { display: 'block' } : { display: 'none' }}>
                    <CardHeader className={classes.cardHeaderTitle}

                        title={this.state._addRemoveMemberBoardName}
                    />
                    <ValidatorForm onSubmit={this.searchHandleSubmitAddRemoveMemberData} onError={errors => console.log(errors)} ref="form"
                        id='id_ValidatorForm'>
                        <CardContent>

                                <Grid container spacing={2} alignItems="flex-end">

                                <Grid item xs={12}> <br />
                                       <AssignmentIcon style={{ marginRight: "2px" }} />
                                       <SelectValidator
                                           //validators={['_isTaskTypeSelected', 'required']}
                                           //errorMessages={['Task type is required']}
                                           id="_addRemoveMember"
                                           name="_addRemoveMember"
                                           placeholder="Remove Member"
                                           value={this.state._addRemoveMember}
                                           onChange={(e) => this._addRemoveMemberHandleChangeTaskTypes(e)}
                                           className={classes.cardTextField}>
                                           <MenuItem value={this.state._addRemoveMember}>Remove Member</MenuItem>
                                           {this.renderAddRemoveMemberOptions()}
                                       </SelectValidator>
                                   </Grid>

                                    <Grid item xs={1}>
                                       <AlternateEmailIcon style={{ marginRight: "2px" }} />
                                    </Grid>
                                    <Grid item xs={11}  >
                  {this.state._addRemoveMemberText_add_emailItem.map(item => (
                    <div className="tag-item" key={item}>
                      {item}
                      <button
                        type="button"
                        className="button"
                        onClick={() => this.handleAddRemoveMemberDeleteEmail(item)}
                      >
                        &times;
            </button>
                    </div>
                  ))}

                  <TextValidator
                    id="input_text_add_email"
                    label="Add email and press enter/tab"
                    name='input_text_add_email'
                    className={classes.cardTextField}
                    onChange={this.handleAddRemoveMemberChangeEmail}
                    value={this.state._addRemoveMemberText_add_email}
                    validators={['isEmail']}
                    errorMessages={['this field is required', 'email is not valid']}
                    onPaste={this.handleAddRemoveMemberPasteEmail}
                    onKeyDown={this.handleAddRemoveMemberKeyDown}
                  />
                </Grid>

                            </Grid>
                        </CardContent>
                        <CardActions>
                            <div style={{ right: "50%" }}>
                                <Button size="small" type="submit" variant="contained" color="primary" style={(this.state._AddRemoveMemberSaveBtnStatus) ? { display: 'inline' } : { display: 'none' }}>Save Changes</Button>
                            </div>
                        </CardActions>
                    </ValidatorForm>

                </Card>
            );
         }

        const renderAddOrRemoveMemberResultFilter = () => {
            debugger;
            let boards = this.state._searchBoardsInfo;
            if (boards.length === 0) {
                return <List component="nav" aria-label="main mailbox folders">
                    <ListItem
                        button
                        selected={this.state.selectedAddRemoveMemberIndex === 0}
                        onClick={event => this.handleAddRemoveMemberListItemClick(event, 0, '')}>
                        <ListItemAvatar>
                            <Avatar alt="avatar">a</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Sorry nothing was found"
                        />
                    </ListItem>
                </List>

            } else {
                return (
                    <List component="nav" aria-label="main mailbox folders">
                        {boards.map((sectionId, index) => {
                            return (
                                <ListItem button
                                    selected={this.state.selectedAddRemoveMemberIndex === index}
                                    onClick={event => this.handleAddRemoveMemberListItemClick(event, index, sectionId)}
                                    key={index} >
                                    <ListItemAvatar >
                                    <Tooltip title={sectionId.name}>
                                        <Avatar alt="avatar" className={classes.avatarListCklr}>{sectionId.name.charAt(0)}</Avatar>
                                        </Tooltip>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<React.Fragment>
                                            <Typography
                                                color="textPrimary"
                                                style={{ fontSize: "15px" }} >
                                                {sectionId.name}
                                            </Typography>
                                        </React.Fragment>}
                                    />
                                </ListItem>
                            );

                        })}
                    </List>
                );
            }

        };

        const showCommentCreatorCategory = (openCardInfoContent) => {
            debugger;
            let categoryValue = (this.state.openCardInfoContent !== null) ? ((this.state.openCardInfoContent.labels.length > 0) ? this.state.openCardInfoContent.labels[0].name : '') : '',
                categoryTitle = (this.state.openCardInfoContent !== null) ? ((this.state.openCardInfoContent.labels.length > 0) ? this.state.openCardInfoContent.labels[0].name : '') : '',
                categoryName = (this.state.openCardInfoContent !== null) ? ((this.state.openCardInfoContent.labels.length > 0) ? this.state.openCardInfoContent.labels[0].name : '') : '',
                categoryStyle = (this.state.openCardInfoContent !== null) ? ((this.state.openCardInfoContent.labels.length > 0) ? this.state.openCardInfoContent.labels[0].color : '') : 'white',
                categorystyleName = (this.state.openCardInfoContent !== null) ? ((this.state.openCardInfoContent.labels.length > 0) ? this.state.openCardInfoContent.labels[0].name : '') : '',
                category_data_my_Value = JSON.stringify((this.state.openCardInfoContent !== null) ? this.state.openCardInfoContent.labels : {});
            debugger;
            return (
                (openCardInfoContent !== null) ?
                    <MenuItem
                        value={categoryValue}
                        title={categoryTitle}
                        name={categoryName}
                        data-my-value={category_data_my_Value}>
                        <LabelIcon style={categoryStyle} />
                                            &nbsp; &nbsp;{categorystyleName}
                    </MenuItem> : ''
            )
        }

        const _searchCardDetailClickedItem = () =>{
           return (
               <Card style={(this.state._searchShowCardInfo) ? { display: 'block' } : { display: 'none' }}>
                   <CardHeader className={classes.cardHeaderTitle}

                       title={this.state._searchCardBoardName}
                   />
                   <ValidatorForm onSubmit={this.searchHandleSubmitData} onError={errors => console.log(errors)} ref="form"
                       id='id_ValidatorForm'>
                       <CardContent>
                           <Grid container>
                               <Grid item xs={6}>
                                   &nbsp;&nbsp;Add task to your calendar
                            <Checkbox disabled={this.state._CheckboxReadOnly}
                                       checked={this.state._CheckedOffice365}
                                       onChange={searchHandleCheckedOffice365('CheckedOffice365')}
                                       value="checkedB"
                                       color="primary"
                                       inputProps={{ 'aria-label': 'secondary checkbox' }} />
                               </Grid>
                               <Grid container spacing={2} alignItems="flex-end">
                                   <Grid item xs={6} style={{ display: this.state._HideShowOffice365Date }}>
                                       <span className="star">*</span> <EventIcon
                                           style={{ marginRight: "2px", marginTop: "20px" }} />
                                       <TextValidator
                                           name='startDate'
                                           id="startDate"
                                           label="Start Date"
                                           type="datetime-local"
                                           onChange={this.searchHandleStartDateChange}
                                           validators={['_isStartDateCaptured', 'required']}
                                           errorMessages={['Start date is required']}
                                           value={this.state._startDate}
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           visiability={this.state._CheckedOffice365.toString()} />
                                   </Grid>
                                   <Grid item xs={6}>
                                       <span className="star">*</span> <EventIcon
                                           style={{ marginRight: "2px", marginTop: "20px" }} />
                                       <TextValidator
                                           onChange={this.searchHandleDueDateChange}
                                           validators={['_isDueDateCaptured', '_isEndDateGreaterStartDateCheck', 'required']}
                                           errorMessages={['Due date is required.', 'Start date cannot be greater than end date.']}
                                           name='dueDate'
                                           id="dueDate"
                                           label="Due Date"
                                           type="datetime-local"
                                           value={this.state._dueDate}
                                           InputLabelProps={{
                                               shrink: true,
                                           }} />
                                   </Grid>
                                   <Grid item xs={12}> <br />
                                       <span className="star">*</span> <AssignmentIcon style={{ marginRight: "2px" }} />
                                       <SelectValidator
                                           validators={['_isTaskTypeSelected', 'required']}
                                           errorMessages={['Task type is required']}
                                           id="taskTypes"
                                           name="taskTypes"
                                           placeholder="Select task type"
                                           //defaultValue={this.state._TaskTypeState}
                                           value={this.state._TaskTypeState}
                                           onChange={(e) => this.searchHandleChangeTaskTypes(e)}
                                           className={classes.cardTextField}>
                                           <MenuItem value={this.state._TaskTypeState}>Select Task Type</MenuItem>
                                           {this.searchRenderTaskTypesOptions()}
                                       </SelectValidator>
                                   </Grid>
                                   <Grid item xs={12}>
                                       <span className="star">*</span> <ShortTextIcon style={{ marginRight: "2px" }} />
                                       <TextValidator
                                           validators={['_isSubject', 'required']}
                                           errorMessages={['Subject is required']}
                                           name='subject'
                                           id="subject"
                                           label="Subject"
                                           placeholder="Enter subject"
                                           className={classes.cardTextField}
                                           value={this.state._title}
                                           onChange={(evt) => this.searchHandleAddSubject(evt)} />
                                   </Grid>
                                   <Grid item xs={12}> <br />
                                       <SubjectIcon style={{ marginRight: "2px" }} />
                                       <TextValidator name='cardDesc' id="cardDesc"
                                           label="Description"
                                           placeholder="Enter task description"
                                           multiline
                                           rows={2}
                                           cols={8}
                                           className={classes.cardTextField}
                                           defaultValue={this.state._cardDesc} />
                                   </Grid>
                               </Grid>
                               <Grid item xs={12}>
                                   <GroupAddIcon style={{ marginRight: "15px", marginTop: "10px" }} /> Add members <br />
                                   <Autocomplete className={classes.cardAddMember}
                                       multiple
                                       options={this.state._currentBoardMembers}
                                       getOptionLabel={option => option.fullName}
                                       value={this.searchDefaultValueAutoComplete()}
                                       onChange={(evt) => this.searchHandleAddMember(evt)}
                                       disableClearable={true}
                                       renderTags={(value, { className, onDelete }) =>
                                           value.map((option, index) => (
                                               <Chip
                                                   key={option.id}
                                                   avatar={<Avatar alt={option.avatarHash}
                                                       src={searchDefaultAvatar(option)}
                                                       className="bigAvatar" />}
                                                   //disabled={index === 0}
                                                   data-tag-index={index}
                                                   tabIndex={-1}
                                                   label={option.fullName}
                                                   className={className}
                                                   onDelete={(e) => this.searchHandleDeleteMember(e, option, value)}
                                               />
                                           ))
                                       }
                                       renderInput={params => (
                                           <TextField
                                               {...params}
                                               className={classes.input}
                                               variant="outlined"
                                               fullWidth />
                                       )} />
                               </Grid>
                           </Grid>
                       </CardContent>
                       <CardActions>
                           <div style={{ left: "50%", display: 'inline' }}>
                               <Button size="small" variant="contained" color="primary"  onClick={(e)=>this.handleMoveLaneClick(e,this.state._searchLaneCardValue)}>Move Task</Button>
                               <StyledMenu
                                   id="customized-menu"
                                   anchorEl={this.state.anchorEl}
                                   keepMounted
                                   open={Boolean(this.state.anchorEl)}
                                   onClose={this.handleMoveLaneClickClose}

                               >
                                   {renderLaneResultFilter()}
                               </StyledMenu>
                           </div>
                           <div style={{ right: "50%" }}>
                               <Button size="small" type="submit" variant="contained" color="primary" style={(this.state._searchSaveBtnStatus) ? { display: 'inline' } : { display: 'none' }}>Save Changes</Button>
                           </div>
                       </CardActions>
                   </ValidatorForm>

               </Card>
           );
        }
        const renderLaneResultFilter = () => {
          debugger;
            let availableLane =[];

            for(let d = 0; d< this.state._searchGetAllLanesPerboard.length;d++){
                if(this.state._searchGetAllLanesPerboard[d].id !== this.state._searchLaneCardValue.id){
                    availableLane.push(this.state._searchGetAllLanesPerboard[d]);
                }
            }
                        debugger;
                        //let res = this.state.SearchAllTrelloCardsResultSet;
                        if (availableLane.length === 0) {
                            return <StyledMenuItem>
                            <ListItemIcon>
                                                     <SendIcon fontSize="small" />
                                                 </ListItemIcon>
                                                 <ListItemText primary="Sent mail" />
                            </StyledMenuItem>

                        } else {
                            return (
                                    availableLane.map((sectionId, index) => {
                                        return (
                                            <StyledMenuItem
                                             //   selected={this.state._selectedLaneSearchIndex === index}
                                                onClick={event => this.handleMoveLaneItemClick(event, index, sectionId)}
                                                key={index} >
                                                <ListItemIcon>
                                                       <SendIcon fontSize="small" />
                                                   </ListItemIcon>
                                                <ListItemText
                                                    primary={<React.Fragment>
                                                        <Typography
                                                            color="textPrimary"
                                                            style={{ fontSize: "15px" }} >
                                                            {sectionId.name}
                                                        </Typography>
                                                    </React.Fragment>}
                                                />
                                            </StyledMenuItem>
                                        );

                                    }) );
                        }

                    };


        const searchHandleCheckedOffice365 = () => event => {
            debugger;
            this.setState({ _CheckedOffice365: event.target.checked });
            if (this.state._CheckedOffice365 === true) {
                debugger;
                this.setState({
                    _startDate: moment('1800-01-01T00:00').format('YYYY-MM-DD[T]HH:mm'),
                    _HideShowOffice365Date: 'none',
                    _cardDesc: document.getElementById('cardDesc').value
                })

                // console.log('Start TIME -----' + this.state.startDate);
            } else {
                debugger;
                this.setState({
                    _startDate: moment(Date.now()).format('YYYY-MM-DD[T]HH:mm'),
                    _HideShowOffice365Date: 'none',
                    _cardDesc: document.getElementById('cardDesc').value
                })
                ValidatorForm.addValidationRule('_isStartDateCaptured', (value) => {
                    debugger;
                    if ((value === 'Invalid date') || (value === '') || (value === undefined)) {
                        return false;
                    }
                    return true;
                });

                //Add validation Rule
                ValidatorForm.addValidationRule('_isStartDateCaptured', value => value);
                this.setState({ _HideShowOffice365Date: 'block' });
            }
        };
        const searchDefaultAvatar = (option) => option.avatarHash === undefined ? DummyAvator : option.avatarUrl + '/170.png';

        let trelloAssignedTabs, data;
        if (this.state.boards.length !== 0) {
            trelloAssignedTabs = this.state.boards.map((board, index) => <Tab label={board.name} key={index} value={board.id} />);
        }

        (this.state.cards === null) ? data = { "lanes": [] } : data = this.state.cards;
        let displayCustomizedDialogShow = (this.state.tabClick) ? displayCustomizedDialog() : '';

        return (
            <div className={classes.root}>
               <form className={classes.root} noValidate autoComplete="off" onSubmit={e => {e.preventDefault();
                 this.handleSearchSubmit(e);}}>
                    <div className={classes.wrapper}>
                        <Input placeholder="" value={this.state.searchValueQuery} inputProps={{ 'aria-label': 'description' }} onChange={(e) => { this.handleSearchChange(e) }} />
                        <Button variant="contained" color="primary" onClick={(e) => {
                              this.handleSearchSubmit(e);
                        }}>Search</Button>
                        <Button variant="contained" color="primary" style={(Cookies.get('role') === 'BRANCH MANAGER')?{ display: 'inline-block', margin: '8px' } : { display: 'none' }} onClick={(e) => {
                              this.handleAddRemoveMemberSubmit(e);
                        }}>Board Members</Button>
                        <List component="nav" aria-label="Device settings" className={classes.linkEmployerWidth}>
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="View Employers"
          onClick={handleEmployersClickListItem}>

          <ListItemText primary="View Employers" secondary={this.state.userEmployersBoardList[this.state.selectedEmployersIndex].employer_display_name} />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={this.state.anchorElEmployers}
        keepMounted
        open={Boolean(this.state.anchorElEmployers)}
        onClose={handleEmployersClose}
      >
        {this.state.userEmployersBoardList.map((option, index) => (

          <MenuItem
            key={index}

            selected={index === this.state.selectedEmployersIndex}
            onClick={(event) => handleEmployersMenuItemClick(event, index,option)}
          >
            {option.employer_display_name}
          </MenuItem>
        ))}
      </Menu>
                    </div>
                </form>
                <br></br>



                <AppBar position="static" style={{ borderRadius: '5px',width: (Math.floor(window.innerWidth)-230) + 'px'}}>
                    <Tabs value={this.state.value} onChange={this.handleChange} onLoad={this.handleChange}
                        variant="scrollable"
                        scrollButtons="auto" TabIndicatorProps={{
                            style: {
                                backgroundColor: "#9173f4"
                            }
                        }}>
                        {trelloAssignedTabs}

                    </Tabs>
                </AppBar>
                <LoadingOverlay
                    active={this.state.overlay}
                    spinner={<Loader type="ThreeDots" color="#3f51b5" height={80} width={80} />}

                >
                    <div className='MyBoards' >
                        {/*<div className="AlignLoader">*/}
                        {/*    <Loader type="Rings" color="#3f51b5" height={200} width={200}  visible = {this.state.isLoaded}/>*/}
                        {/*</div>*/}

                        <Board data={data}
                            style={{ backgroundColor: '#FAFAFA', height: 'auto', width: "auto" }}
                            draggable
                            id="EditableBoard1"
                            handleDragEnd={handleDragEnd.bind(this, data)}
                            onCardAdd={onCardAdd}
                            onCardDelete={onCardDelete}
                            onCardClick={onCardClick.bind(this, data)}
                            editable
                            collapsibleLanes
                            laneDraggable={false}
                            components={{
                                NewCardForm: CustomCardForm
                            }}
                        />
                        {displayCustomizedDialogShow}
                        {displayOutcomesDialog()}
                    </div>

                    <Dialog disableBackdropClick disableEscapeKeyDown aria-labelledby="confirmation-dialog-title" open={this.state.openSearchDialog} onClose={this.showDialogContentCancel} fullWidth={true}
                        maxWidth={"md"} scroll={'paper'}>
                        <DialogTitle id="customized-dialog-title"
                            onClose={this.showDialogContentCancel} >
                            <Grid container direction="row">
                                <Grid item>
                                <Loader type="ThreeDots" color="#3f51b5" height={30} width={40}
                                    visible={this.state._isLoaded}/>
                                </Grid>
                                <Grid item>&nbsp; Search Results</Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent className={classes.cardContentInfo}>
                            <Grid container direction="row" spacing={1} dividers={'paper'}>
                                <Grid item sm={4}>
                                    <Paper className={classes.paper}>
                                        {renderSearchResultFilter()}
                                    </Paper>
                                </Grid>
                                <Grid item sm={8}>
                                {(this.state._searchShowCardInfo)? _searchCardDetailClickedItem():this.state._searchErrorCardMessage}

                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={this.showDialogContentCancel} color="primary">
                                Cancel
        </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog disableBackdropClick disableEscapeKeyDown aria-labelledby="confirmation-dialog-title" open={this.state.openAddRemoveDialog} onClose={this.showAddRemoveDialogContentCancel} fullWidth={true}
                        maxWidth={"md"} scroll={'paper'}>
                        <DialogTitle id="customized-dialog-title"
                            onClose={this.showAddRemoveDialogContentCancel} >
                            <Grid container direction="row">
                                <Grid item>
                                <Loader type="ThreeDots" color="#3f51b5" height={30} width={40}
                                    visible={this.state._isLoaded}/>
                                </Grid>
                                <Grid item>&nbsp; Add/Remove Member</Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent className={classes.cardContentInfo}>
                            <Grid container direction="row" spacing={1} dividers={'paper'}>
                                <Grid item sm={4}>
                                <Typography variant="h4">
                                                            Select Worksite
                                                        </Typography>
                                    <Paper className={classes.paper}>
                                        {renderAddOrRemoveMemberResultFilter()}
                                    </Paper>
                                </Grid>
                                <Grid item sm={8}>
                                {(this.state._addRemoveMemberShowCardInfo)? _addRemoveMemberCardDetailClickedItem():this.state._addRemoveMemberErrorCardMessage}

                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={this.showAddRemoveDialogContentCancel} color="primary">
                                Cancel
        </Button>
                        </DialogActions>
                    </Dialog>





                </LoadingOverlay>

            </div>
        );
    }
}
export default withStyles(styles)(Boards);
