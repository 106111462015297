import { Component } from 'react'
import axios from 'axios';
import {API_CONFIG} from "../../config/api-config";

export class boardsService extends Component {

  constructor(props) {
  super(props);
  this.state = {};
  this.params = {
    apiUrl: API_CONFIG.concat('/trello/')
  };

   
 /*Get Boards Navigation*/
   this.GetTrelloBoardNavigation = (data)=>{
    const headers = {
      "Content-Type": "application/json",
       "Access-Control-Allow-Origin": "*" 
    };
     return axios.get(this.params.apiUrl  + 'getMemberTrelloBoards?key=' + data.key + '&token='+  data.token, headers)
     }
     
   /*Get Adviser Information */ 
   this.GetMemberInformation = (data) =>{
    const headers = {
      "Content-Type": "application/json",
       "Access-Control-Allow-Origin": "*" 
    };

        return axios.get(this.params.apiUrl  + 'getMemberInformation?key=' + data.key + '&token='+  data.token, headers)

   } 

   /** Get All Comments for Card */
   this.GetUserCardCommentNavigation = (cardId, data) => {
    const headers = {
      "Content-Type": "application/json",
       "Access-Control-Allow-Origin": "*" 
    };

        return axios.get(this.params.apiUrl  + `lookUpAllCardComments/access/${data.key}/token/${data.token}/cardId/${cardId}`, headers);

   }

   /*Get Adviser Assigned Tasks Information */ 
   this.GetAdviserAssignedMemberCardsInformation = (data) =>{
        const headers = {
          "Content-Type": "application/json",
           "Access-Control-Allow-Origin": "*"
        };
       return axios.get(this.params.apiUrl  + 'getMemberAssignedCards?idMember='+data.idMember+'&key=' + data.key + '&token='+  data.token, headers)
     }
   }
  }
export default boardsService
