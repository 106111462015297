import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';
import { withStyles } from '@material-ui/core/styles';
import CardsService from '../../shared/cardService';
import boardsService from '../../shared/boardsService';
import avatarService from  '../../shared/AvatarService';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import CardDataBoard from './CardDataBoard';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

const style = theme => ({
  root: {
    display: 'flex',
   /* flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,*/
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  tabRoot:{
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },  
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },   
  title: {
    flexGrow: 1,
  }, 
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(0),
    paddingRight:theme.spacing(0),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
});

class AuditFlowDashboard extends React.Component  {
  
  
  constructor(props) {
    super(props);
    this.boardsService = new boardsService();
    this.CardsService = new CardsService();
    this.avatarService = new avatarService();
    this.state = {
      columnNames : [],
      columnDataZZZ : [],
      timeDuration:'',
      start_deposit_session:new Date(),
      value: 0,
      userDuration:[],
      roleAccessData: this.props.data
    }
    debugger;
  }

  componentDidMount() {
   
    this.listAuditTableDate();
  }

ordersCallback = (dataFromChild) => {
  const ordersDataChild = dataFromChild;
  debugger;
  const session_start_time = ordersDataChild.session_start_time;
  const session_end_time = ordersDataChild.session_end_time;
  const result_session_end_time =(session_end_time !== null)? new Date(session_end_time).getTime() - new Date(session_start_time).getTime():'undefined';
  function msToTime(duration) {
    var milliseconds = parseInt((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  
    hours = (hours < 10) ? "0" + hours : hours;
    const hourHolders = parseInt(hours);
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    const minutesHolders = parseInt(minutes);
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    const hsecondsHolders = parseInt(seconds);
    let results = '';
    if( hourHolders <= 0 && minutesHolders <= 0){
      results = seconds+ " sec";
    }else if( hourHolders <= 0 && minutesHolders > 0){
      results = minutes + " min : " + seconds + " sec";
    }else{
      results= hours + " hrs : " + minutes + " min : " + seconds + " sec";
    }

      
    return results;
  }
  this.setState({
    timeDuration: (result_session_end_time !== 'undefined')?msToTime(result_session_end_time):'session not closed',
    start_deposit_session:new Date(session_start_time)
  });
}

chartsCallback = (dataFromChild) => {
  const chartsDataChild = dataFromChild;
  debugger;
  
}

mainCallback = (dataFromChild) => {
  const mainDataChild = dataFromChild;
  debugger;
 
}

  listAuditTableDate = () => {
    const parseJwt = token => {
      try {
          return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
          return null;
      }
  };
  function groupBy(xs, f) {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }
  function sortFunction(a,b){  
    var dateA = new Date(a.start_time).getTime();
    var dateB = new Date(b.start_time).getTime();
    return dateA > dateB ? 1 : -1;  
};

  const access_name = parseJwt(localStorage.getItem('mmih_auth_token')).sub.sAMAccountName;
    this.avatarService.getAuditLogin({'access_name': access_name}).then(res=>{
      const auditData = [];
      const userStartEnd = [];
      for(let d = 0; d < res.data.length; d++ ){
        userStartEnd.push({user:res.data[d].username, start_time: res.data[d].session_start_time, end_time:res.data[d].session_end_time});
        auditData.push([res.data[d].username,res.data[d].session_start_time,res.data[d].session_end_time,res.data[d].sys_os_model,
          res.data[d].browser_type,res.data[d].login_user_role,res.data[d].admin_username]);
      }
      userStartEnd.sort(sortFunction);
      const result = groupBy(userStartEnd, (c) => c.user);
      let storeLastRecord = [];
      for (let [key, value] of Object.entries(result)) {
        if(access_name.toLowerCase()===key.toLowerCase()){
          storeLastRecord.push({user_identity:key, entries:{firstEntry:value[value.length -2]}});
        }else{
          storeLastRecord.push({user_identity:key, entries:{firstEntry:value[value.length -1]}});
        }        
        
      }
      debugger;
      this.setState({
        tableColumnData:auditData,
        userDuration: storeLastRecord
      });
    },error => {
      this.setState({
        tableColumnData:[]
      });

    });
  }

  handleTabChange = (event, newValue) => {
    this.setState({value:newValue, timeDuration: '',start_deposit_session:new Date()});
  };


  render() {
  const {classes} = this.props;
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let currentDay = this.state.start_deposit_session.getDate(), currentMonth =months[this.state.start_deposit_session.getMonth()], currentYear = this.state.start_deposit_session.getFullYear();
  debugger;
  const boardDate = {
    duration:this.state.timeDuration,
    exactDate:`on ${currentDay} ${currentMonth}, ${currentYear}`
  }
debugger;
  return (
    <div className={classes.tabRoot}> 
    <AppBar position="static">
        <Tabs
          value={this.state.value}
          onChange={this.handleTabChange}
          variant="scrollable"
          scrollButtons="off"
          aria-label="scrollable prevent tabs example"
        >
          <Tab icon={<PersonPinIcon />} label="log results" aria-label="access" {...a11yProps(0)} />
          <Tab icon={<ShoppingBasket />} label="Card Stats" aria-label="dashboard" {...a11yProps(1)} />         
        </Tabs>
      </AppBar> 
      <TabPanel value={this.state.value} index={0}>    
        <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        
          <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper className={fixedHeightPaper}>
                <Chart chartData={this.state.userDuration} />
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper}>
                <Deposits boardData={boardDate}  />
              </Paper>
            </Grid>
            {/* Recent Orders */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Orders /*boardData={this.state.columnData}*/ callbackOrdersFromParent={this.ordersCallback}/>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        
      </main>
      </TabPanel>
      <TabPanel value={this.state.value} index={1}>
        <CardDataBoard  data={this.state.roleAccessData}/>
      </TabPanel>
    </div>
  );
}
}
export default withStyles(style)(AuditFlowDashboard);