export const basicNavigate = [
  {
    id: 1,
    name: "Home",
    iconStyle: "HomeIcon",
    iconDescription: "Get in and move up",
    navPathLink: "/",
    role:'user'
  },
  {
    id: 2,
    name: "Dashboard",
    iconStyle: "InboxIcon",
    iconDescription: "Get in and move up",
    navPathLink: "/Dashboard",
    role:'admin'
  },
  {
       id: 3,
       name: "Assessment",
       iconStyle: "Assessment",
       iconDescription: "View channels performance",
       navPathLink: "/channel-metrix",
       role:'admin'
     },
  // {
  //   id: 2,
  //   name: "Dashboard",
  //   iconStyle: "Dashboard",
  //   iconDescription: "Get in and move up",
  //   navPathLink: "/Dashboard"
  // },
  // {
  //   id: 3,
  //   name: "Goals",
  //   iconStyle: "Dashboard",
  //   iconDescription: "Get in and move up",
  //   navPathLink: "/Goals"
  // },
  // {
  //   id: 2,
  //   name: "Notifications",
  //   iconStyle: "AddAlert",
  //   iconDescription: "Get in and move up",
  //   navPathLink: "/Inbox"
  // },
  {
    id: 3,
    name: "Calendar",
    iconStyle: "AssignmentTurnedIn",
    iconDescription: "Get in and move up",
    navPathLink: "/ScheduleCalendar",
    role:'user'
  },
];

export const basicBoardsNavigate = [
  {
    id: 1,
    name: "Worksite Tasks",
    iconStyle: "MailIcon",
    iconDescription: "Get in and move up",
    navPathLink: "/Boards",
    role:'user'
  },
  {
    id: 2,
    name: "Solutions",
    iconStyle: "MailIcon",
    iconDescription: "Get in and move up",
    navPathLink: "/Solutions",
    role:'user'
  },
   {
     id: 3,
      name: "Tools",
     iconStyle: "BuildIcon",
     iconDescription: "Get in and move up",
     navPathLink: "/Tools",
     role:'user'
    },
  {
    id: 4,
    name: "Technical Support",
    iconStyle: "InboxIcon",
    iconDescription: "Get in and move up",
    navPathLink: "/Support",
    role:'user'
  }





];

export const GraphData = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
];

export const GraphDataMonths = [
  {
    name: "Jan",
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: "Feb",
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: "Mar",
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: "Apr",
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: "May",
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: "Jun",
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: "Jul",
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
];


export const GraphPolicyData = [
  {
    name: "Week 1",
    Goal: 8,
    comm : 1200,
    Actual: 7,

  },
  {
    name: "Week 2",
    Goal: 5,
    comm : 2600,
    Actual: 3
  },
  {
    name: "Week 3",
    Goal: 3,
    comm : 1000,
    Actual: 3
  },
  {
    name: "Week 4",
    Goal: 5,
    comm : 5000,
    Actual: 2
  },
];



export const GraphCommissionData = [
  {
    name: "Week 1",
    Goal: 8,
    Actual: 2,
    comm : "R1200",
  },
  {
    name: "Week 2",
    Goal: 5,
    Actual: 3,
    comm : "R2600",

  },
  {
    name: "Week 3",
    Goal: 3,
    Actual: 4,
    comm : "R800",

  },
  {
    name: "Week 4",
    Goal: 5,
    Actual: 6,
    comm : "R300",

  },
];
