import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import MaterialTable from "material-table";
import MUIDataTable from "mui-datatables";
import { withStyles } from '@material-ui/core/styles';
import CardsService from '../../shared/cardService';
import boardsService from '../../shared/boardsService';
import avatarService from  '../../shared/AvatarService';
import Typography from '@material-ui/core/Typography';


// Generate Order Data
function createData(id, username, session_start_time, session_end_time, sys_os_model,login_user_role, sessionid) {
  return { id:id,  username:username, session_start_time:session_start_time, session_end_time:session_end_time, sys_os_model:sys_os_model,login_user_role:login_user_role, sessionid:sessionid };
}




const rows = [
  createData(0, 'met_test1', '2020-05-11 16:04:57.799498', '2020-05-11 16:22:45.234', 'Linux x86_64','User','81340de4-0632-30cf-ae49-946c398300dd'),
  createData(1, 'met_test3', '2020-03-10 12:23:10.294172', '2020-03-11 14:27:01.202000', 'Linux x86_64', 'User','ed04fd95-7216-3cec-9d48-5cb3f0e176cf'),
  createData(2, 'met_test3', '2020-03-04 10:43:24.532963', '2020-03-04 10:51:48.275000', 'MacIntel', 'Admin','a8f2935c-fa00-3d0d-8d9a-ac87a09dcb55'),
  createData(3, 'met_test2', '2020-03-04 12:32:33.426463', '2020-03-04 12:38:25.426000', 'Win32', 'User','88324efb-a5d3-381c-9c31-b210b450f7b2'),
  createData(4, 'met_test2', '2020-03-12 10:47:48.050760', '2020-03-12 10:58:48.847000', 'Win32', 'User','d77ab2f5-1461-3e9c-9bff-b8dba8d94f06'),
];
function preventDefault(event) {
  event.preventDefault();
}
const style = theme =>({
  seeMore: {
    marginTop: theme.spacing(3),
  },
});

export class Orders extends React.Component {
  constructor(props) {
    super(props);
    
    this.boardsService = new boardsService();
    this.CardsService = new CardsService();
    this.avatarService = new avatarService()
    this.state = {
      tableColumnNames : [],
      tableColumnData : [],
      userDuration: []

    }
  
  }

  componentDidMount() {
    this.listColumnName();
    this.listAuditTableDate();
  }


populateDepositData = ( username, session_start_time, session_end_time, browser_type,login_user_role, admin_username) => {
    
    const storeDepositDate = {  username:username, session_start_time:session_start_time, session_end_time:session_end_time, browser_type:browser_type,login_user_role:login_user_role, admin_username:admin_username };
    debugger;
    this.props.callbackOrdersFromParent(storeDepositDate);
  }

  populateOrderData = ( username, session_start_time, session_end_time, browser_type,login_user_role, admin_username) => {
    
    return {  username:username, session_start_time:session_start_time, session_end_time:session_end_time, browser_type:browser_type,login_user_role:login_user_role, admin_username:admin_username };
    
  }

  listColumnName = () => {
    const columnsz = [
      {
        name: "username", label: "Username",
        options: {
          filter: true,
          sort: false,
        }
      },
      {
        name: "session_start_time", label: "Start time",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "session_end_time", label: "End time",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "sys_os_model", label: "OS Model",
        options: {
          filter: true,
          sort: false,
        }
      },
      {
        name: "browser_type", label: "Browser type",
        options: {
          filter: true,
          sort: false,
        }
      },
      {
        name: "login_user_role", label: "User role",
        options: {
          filter: true,
          sort: false,
        }
      },
      {
        name: "admin_username", label: "Admin username",
        options: {
          filter: true,
          sort: false,
        }
      }];
    this.setState({
      tableColumnNames:columnsz
    });
  }

  listAuditTableDate = () => {
    const parseJwt = token => {
      try {
          return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
          return null;
      }
  };

  function groupBy(xs, f) {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }
  function sortFunction(a,b){  
    var dateA = new Date(a.start_time).getTime();
    var dateB = new Date(b.start_time).getTime();
    return dateA > dateB ? 1 : -1;  
};

  const access_name = parseJwt(localStorage.getItem('mmih_auth_token')).sub.sAMAccountName;
    this.avatarService.getAuditLogin({'access_name': access_name}).then(res=>{
      const auditData = [];
     // const userStartEnd = [];
      for(let d = 0; d < res.data.length; d++ ){
        auditData.push({username:res.data[d].username,session_start_time:res.data[d].session_start_time,session_end_time:res.data[d].session_end_time,sys_os_model:res.data[d].sys_os_model,
          browser_type:res.data[d].browser_type,login_user_role:res.data[d].login_user_role,admin_usernam:res.data[d].admin_usernam});
     //   userStartEnd.push({user:res.data[d].username, start_time: res.data[d].session_start_time, end_time:res.data[d].session_end_time});
       /* auditData.push([res.data[d].username,res.data[d].session_start_time,res.data[d].session_end_time,res.data[d].sys_os_model,
          res.data[d].browser_type,res.data[d].login_user_role,res.data[d].admin_username]);*/
      }
     // userStartEnd.sort(sortFunction);
    //  const result = groupBy(userStartEnd, (c) => c.user);
      debugger;
      this.setState({
        tableColumnData:auditData,
     //   userDuration: result
      });
    },error => {
      this.setState({
        tableColumnData:[]
      });

    });
  }

  


  render() {
    const { classes } = this.props;
  

  const dataz = [
    ["met_test1", "2020-05-15 10:54:40.379111", "2020-05-15 10:54:45.119", "Linux x86_64", "chrome","User",""],
    ["met_test2", "2020-05-15 10:54:40.379111", "2020-05-15 11:54:45.119", "Linux x86_64", "chrome","User",""],
    ["met_test3", "2020-05-15 10:54:40.379111", "2020-05-15 11:54:45.119", "Linux x86_64", "chrome","User",""],
    ["met_test2", "2020-05-15 10:54:40.379111", "2020-05-15 12:54:45.119", "Linux x86_64", "chrome","User",""],
    ["met_test1", "2020-05-15 10:54:40.379111", "2020-05-15 17:54:45.119", "Linux x86_64", "chrome","User",""],
    ["met_test1", "2020-05-15 10:54:40.379111", "2020-05-15 15:54:45.119", "Linux x86_64", "chrome","User",""],
    ["met_test5", "2020-05-15 10:54:40.379111", "2020-05-15 18:54:45.119", "Linux x86_64", "chrome","User",""],
    ["met_test6", "2020-05-15 10:54:40.379111", "2020-05-15 16:54:45.119", "Linux x86_64", "chrome","User",""],
    ["met_test1", "2020-05-15 10:54:40.379111", "2020-05-15 11:54:45.119", "Linux x86_64", "chrome","User",""]    
  ];

  

  const options = {
    filterType: "multiselect",
    responsive: "scroll",
    onRowClick: (action, tableState) => {
      debugger;

      console.log(action, tableState);
      this.populateDepositData(action[0], action[1], action[2], action[3], action[4], action[5]);
      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want

     /* switch (action) {
        case 'changePage':
          this.changePage(tableState.page);
          break;
      }*/
    },
    onRowsSelect: (currentRowSelectedAction, allRowsSelectedAction) => {
      debugger;

      console.log(currentRowSelectedAction, allRowsSelectedAction);
     // this.populateDepositData(action[0], action[1], action[2], action[3], action[4], action[5]);
     
    }
  };
  return (
    <React.Fragment>
     <Typography component="h2" variant="h6" color="primary" gutterBottom>Flow duration data</Typography>
      <MUIDataTable
        //title={"Flow duration data"}
        data={this.state.tableColumnData}
        columns={this.state.tableColumnNames}
        options={options}
      />      
    </React.Fragment>
  );
  }
}
export default withStyles(style)(Orders);