

import React, { Component } from 'react';

import { FormLabel,Link,Avatar,Button,CssBaseline,
    TextField,Paper,Box, Grid,Typography, InputLabel } from '@material-ui/core';  
import Loader from 'react-loader-spinner';
import  LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { withStyles } from "@material-ui/core/styles";
import Layout from "../Layout";
import './style.css';
import avatarService from './../../shared/AvatarService';
import * as Cookies from "js-cookie";
import Container from '@material-ui/core/Container';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import Switch from "@material-ui/core/Switch";
import {grey} from "@material-ui/core/colors";



function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright ©'}
            {' '}
            <Link color="inherit" href="">
                Metropolitan GetUp
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}



const useStyles = theme => ({
    root: {
        height: '100vh',
        paddingLeft: 0,
        overflow: 'none'
    },
    image: {
        backgroundImage: 'url(https://dkxsg3399amor.cloudfront.net/background/bg_login.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        marginTop: theme.spacing(15),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        color: "white"
    },
    form: {
        width: '75%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    errorMsg:{
        textAlign:'center',
        color:'red'
    },
    hidden: {
        display:'none'
    },
    footer: {
        fontSize: '10px',
        textAlign: 'center',
    },
    legislation: {
        // marginTop: theme.spacing(28),
        maxWidth:'30%',
        bottom: 0,
        position: "fixed",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    }

});



class SignInSide extends Component {

    constructor(props) {
        super(props);
        debugger;
        this.avatarService = new avatarService();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            toLayout: false,
            maskState: false,
            isLoaded: false,
            tr_key: '',
            tr_token: '',
            errMsg:'',
            buttonDisplay: false,
            showPassword: false,
            username: '',
            password: ''
        }
    }

    parseJwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleMouseDownPassword = event => {
        event.preventDefault();
    };
     browser_type_info = () => {
        var test = function(regexp) {
            return regexp.test(window.navigator.userAgent);
        }
        switch (true) {
            case test(/edg/i): return "edge";
            case test(/opr/i) && (!!window.opr || !!window.opera): return "opera";
            case test(/chrome/i) && !!window.chrome: return "chrome";
            case test(/trident/i): return "ie";
            case test(/firefox/i): return "firefox";
            case test(/safari/i): return "safari";
            default: return "other";
        }
    };


    handleChange = name => event => {
        debugger;
        this.setState({ ...this.state, [name]: event.target.checked });
      };

    handleSubmit = (event) => {
        
        event.preventDefault();

        const data = new FormData(event.target);
        this.setState({ maskState: true, isLoaded: true });
        var handleSubmitDate = new Date();

        console.log(data.get('email'));
        let authDTO = {
            admin_username: '',
            browser_type: this.browser_type_info(),
            created_at: handleSubmitDate.toLocaleString(),
            device_type: ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) )? "Mobile" :"Laptop/PC",
            id: 0,
            login_user_role: 'User',
            sessionID: navigator.appCodeName.concat('#'+handleSubmitDate.getTime() + 15*60*1000),
            session_end_time: '',
            session_start_time: handleSubmitDate.toLocaleString(),
            sys_os_model: navigator.platform,
            username: ''
          }

        this.setState({
            username: data.get('email'),
            password: data.get('password')
        });

        const user = { 'email': data.get('email'), 'password': data.get('password') };


        if (this.state.checkedF) {
            const adminUser = { 'email': data.get('email'), 'password': data.get('password'), 'adminRole': 'Tele agent'.toUpperCase(),'minicusername':  data.get('minic_user')};

            this.avatarService.GetUpAdminAccessAD(adminUser).then(resultGetUpAccess => {
                    let data = {
                        username: this.parseJwt(resultGetUpAccess.data.token).sub.sAMAccountName,
                        employeeNumber: this.parseJwt(resultGetUpAccess.data.token).sub.employeeNumber
                    };
                authDTO.username = data.username;
                authDTO.admin_username = this.parseJwt(this.parseJwt(resultGetUpAccess.data.token).sub.token_minic).sub.sAMAccountName;
                authDTO.login_user_role = 'Admin';
debugger;
                //Get Role and assign to session
/*                this.avatarService.GetUserRole(data).then(res => {
                    console.log(JSON.stringify(res))
                    if(typeof res.data.primaryRole !== 'undefined') {
                        console.log('GOT PRIMARY ROLE ---> ' + res.data.primaryRole);
                        Cookies.set('role',  res.data.primaryRole);
                    }else{
                        Cookies.set('role',  "No Role for " + data.employeeNumber);
                    }
                });*/
               

                this.avatarService.GetTrelloUserAccessWarehouse(data).then(
                        result => {

                        Cookies.set('auth', resultGetUpAccess.data.token, { expires: 2 });
                        let uname = this.parseJwt(resultGetUpAccess.data.token).sub.mail;
                        var base64Credentials = btoa(uname + ":" + user.password);
                        sessionStorage.setItem("mmih_flow", base64Credentials);
                        sessionStorage.setItem("mmih_auth_token", resultGetUpAccess.data.token);
                        localStorage.setItem('mmih_auth_token', resultGetUpAccess.data.token);
                        localStorage.setItem("mmih_flow", base64Credentials);
                        let data = {
                            key:(result.data.trello_member_key) ? result.data.trello_member_key : '',
                            token:(result.data.trello_member_token) ? result.data.trello_member_token : ''
                        };
                        let userAccessData = window.btoa(data.key+'::Flow::'+data.token);
                        localStorage.setItem("mmih_auth_data", userAccessData);
                        sessionStorage.setItem("mmih_auth_data", userAccessData);
                        this.avatarService
                        .GetTrelloBoardAvatar(data)
                        .then(res => res.data)
                        .then(
                          response => {

                            Cookies.set('member_uuid', response);
                            this.props.history.push("/");
                          },
                          error => {
                           console.log(`user error during auth: ${error}`);
                           this.setState({errMsg:error.toJSON().message});
                          }
                        );
                        this.avatarService
                        .trailAccessAdminOauth(authDTO)                        
                        .then(
                          response => {
                              Cookies.set('sessionid', response.data.sessionID, { expires: 2 });  
                              sessionStorage.setItem("sessionid", response.data.sessionID);
                              localStorage.setItem("sessionid", response.data.sessionID);
                            console.log(`successful auditted auth: ${response.data}`);

                            
                          },
                          error => {
                           console.log(`unsuccessful auditted auth: ${error}`);
                           
                          }
                        );

                    },
                    error => {
                        console.log(`Error on  Domain Authentication - ${error}`);
                        //  debugger;;
                        this.setState({
                            toLayout: false,
                            maskState: false,
                            isLoaded: false,
                            errMsg:error.toJSON().message
                        });

                    }

                    );

            },
                error => {
                    console.log('Error on  Domain Authentication');
                    //  debugger;;
                    this.setState({
                        toLayout: false,
                        maskState: false,
                        isLoaded: false,
                        errMsg:'Please validate your username/password : ' + error.toJSON().message
                    });
                }
            );


    }else {

        this.avatarService.GetUpAccessAD(user).then(resultGetUpAccess => {
            let data = {
                username: this.parseJwt(resultGetUpAccess.data.token).sub.sAMAccountName,
                employeeNumber: this.parseJwt(resultGetUpAccess.data.token).sub.employeeNumber
            };
             //Get Role and assign to session
         /*     this.avatarService.GetUserRole(data).then(res => {
                  console.log(JSON.stringify(res))
                  if(typeof res.data.primaryRole !== 'undefined') {
                      console.log('GOT PRIMARY ROLE ---> ' + res.data.primaryRole);
                       Cookies.set('role',  res.data.primaryRole);
                  }else{
                      Cookies.set('role',  "No Role for " + data.employeeNumber);
                  }
              });
*/
            authDTO.username = data.username;

             this.avatarService.GetTrelloUserAccessWarehouse(data).then(
                    result => {

                    Cookies.set('auth', resultGetUpAccess.data.token, { expires: 2 });
                    let uname = this.parseJwt(resultGetUpAccess.data.token).sub.mail;
                    var base64Credentials = btoa(uname + ":" + user.password);
                    sessionStorage.setItem("mmih_flow", base64Credentials);
                    sessionStorage.setItem("mmih_auth_token", resultGetUpAccess.data.token);
                    localStorage.setItem('mmih_auth_token', resultGetUpAccess.data.token);
                    localStorage.setItem("mmih_flow", base64Credentials);
                    let data = {
                        key:(result.data.trello_member_key) ? result.data.trello_member_key : '',
                        token:(result.data.trello_member_token) ? result.data.trello_member_token : ''
                    };
                    let userAccessData = window.btoa(data.key+'::Flow::'+data.token);
                    localStorage.setItem("mmih_auth_data", userAccessData);
                    sessionStorage.setItem("mmih_auth_data", userAccessData);
                    this.avatarService
                    .GetTrelloBoardAvatar(data)
                    .then(res => res.data)
                    .then(
                      response => {

                        Cookies.set('member_uuid', response);
                        this.props.history.push("/");
                      },
                      error => {
                       console.log(`user error during auth: ${error}`);
                       this.setState({errMsg:error.toJSON().message});
                      }
                    );
                    this.avatarService
                        .trailAccessAdminOauth(authDTO)                        
                        .then(
                          response => {  
                            Cookies.set('sessionid', response.data.sessionID, { expires: 2 });                            
                              sessionStorage.setItem("sessionid", response.data.sessionID);
                              localStorage.setItem("sessionid", response.data.sessionID);
                            console.log(`successful auditted auth: ${response.data}`);

                            
                          },
                          error => {
                           console.log(`unsuccessful auditted auth: ${error}`);
                           
                          }
                        );

                },
                error => {
                    console.log(`Error on  Domain Authentication - ${error}`);
                    //  debugger;;
                    this.setState({
                        toLayout: false,
                        maskState: false,
                        isLoaded: false,
                        errMsg:error.toJSON().message
                    });

                }

                )
        },
            error => {
                console.log('Error on  Domain Authentication');
                //  debugger;;
                this.setState({
                    toLayout: false,
                    maskState: false,
                    isLoaded: false,
                    errMsg:'Please validate your username/password : ' + error.toJSON().message
                });
            }
        );

    }
    };
    render() {
        const { classes } = this.props;

        if (this.state.toLayout === true) {
            return <Layout to='/Layout' data={{ key: this.state.tr_key, token: this.state.tr_token }} />
        }
        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Container component={"main"} maxWidth={"sm"}>
                    <div className={classes.paper}>
                        <Typography component="h1" variant="h5">
                            <img src={'https://dkxsg3399amor.cloudfront.net/final_flow_logo.png' } width="150" /> <br/>
                        </Typography>

                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>

                        <Typography component="h1" variant="h5">
                          Sign in
                    </Typography>
                        <form className={classes.form} noValidate onSubmit={this.handleSubmit} >
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={this.state.checkedF ? 'Admin Username': 'Username'}
                                name="email"
                                autoComplete="email"
                                autoFocus

                            />
                            <div className={this.state.checkedF ? '': classes.hidden}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="minic_user"
                                    label="Username"
                                    id="minic_user"
                                    autoComplete="email"
                                    required
                                />
                            </div>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                ref={"adminPassword"}
                                required
                                fullWidth
                                name="password"
                                label={this.state.checkedF ? 'Admin Password': 'Password'}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                type={this.state.showPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                            >
                                                {this.state.showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Box style={{textAlign: "center"}}>
                            <FormControlLabel className={classes.adminToggle}
                            control={
                                 <Switch color={"primary"} checked={this.state.checkedF} onChange={this.handleChange('checkedF')} value="checkedF" />
                            }
                            label="Admin Login"
                            />
                            </Box>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                name="submit"
                                className={classes.submit}

                            >
                                Sign In
                            </Button>
                            <div style={{textAlign: "center"}}>
                                <Loader type="ThreeDots" color="#3f51b5" height={50} width={50} visible={this.state.isLoaded} />
                                <InputLabel className={classes.errorMsg} htmlFor="my-input">{this.state.errMsg}</InputLabel>
                            </div>
                        </form>
                        <Box mt={3}>
                            <Copyright />
                        </Box>
                    </div>
                        {/*<div className={classes.legislation}>*/}
                        {/*<box mt={3}>*/}
                        {/*    <Typography variant="body2" color="textSecondary" className={classes.footer}>*/}
                        {/*        <Link color="primary" href="">*/}
                        {/*            Metropolitan GetUp*/}
                        {/*        </Link>{' '}*/}
                        {/*        is part of {' '}*/}
                        {/*        <Link color="primary" href="https://www.momentummetropolitan.co.za/en">*/}
                        {/*            Momentum Metropolitan Life Limited*/}
                        {/*        </Link>{' '}*/}
                        {/*        , an authorised financial service (FSP 44673) and registered credit provider (NCRCP173), and rated B-BBEE level 1.*/}
                        {/*    </Typography>*/}
                        {/*</box>*/}
                        {/*</div>*/}
                    </Container>
                </Grid>
                <Grid item xs={false} sm={4} md={7} className={classes.image}/>
            </Grid>
        );
    }
} export default withStyles(useStyles)(SignInSide);
