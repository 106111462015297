import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { BarChart, Bar, Cell,LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, CartesianGrid, Tooltip, Legend, } from 'recharts';
import Title from './Title';
import { Bar as CardStatusBar, Doughnut } from 'react-chartjs-2';
import themeChartCard from './themeChartCard';
import clsx from 'clsx';

// Generate Sales Data
function createData(time, amount) {
  return { time, amount };
}

const data = [
  createData('00:00', 0),
  createData('03:00', 300),
  createData('06:00', 600),
  createData('09:00', 800),
  createData('12:00', 1500),
  createData('15:00', 2000),
  createData('18:00', 2400),
  createData('21:00', 2400),
  createData('24:00', undefined),
];

const dataZZZZ = [
  {
    name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
  },
  {
    name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
  },
  {
    name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
  },
  {
    name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
  },
  {
    name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
  },
  {
    name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
  },
  {
    name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
  },
];

class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      storeChart :[{
        name: 'Page B', uv: new Date('2020-05-13T23:38:06.590623').getTime(), pv: new Date('2020-05-13T08:50:06.590623').getTime(), amt: new Date('2020-05-14T00:50:06.590623').getTime(),
      }],
      fiveWeekBar: [],
    }
    debugger;
  }
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/30763kr7/';

  favoriteColors = [ 
    { codes: '#6b4693'},{ codes: '#cbbeb5' },{ codes: '#cc0000'},  { codes: '#a30000'},  { codes: '#2e0000'},  {
      codes: '#11002c'},  {  codes: '#1b0045'},  { codes: '#4cff4c'},  {codes: '#f44b74'},  {codes: '#00aca1'},  {
      codes: '#3a8a16'},  {codes: '#2c9c91'},  {codes: '#a6c6a4'},  {codes: '#ff2172'},  {codes: '#ca00ff'  },  {
      codes: '#006633'},  {codes: '#333333'}
  ];

  /* chartData = [
    {
      username: 'Page A', session_start_time: new Date(session_start_time), session_end_time: new Date(session_end_time), amt: 2400,
    },
    {
      name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
    },
    {
      name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
    },
    {
      name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
    },
    {
      name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
    },
    {
      name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
    },
    {
      name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
    },
  ];*/

  generate = (numberSelected) => {
    debugger;
    return  numberSelected.map((data, index) => {
      debugger;
      return (
        <React.Fragment>
          <Bar dataKey={data.session_start_time} key={index} fill={this.favoriteColors[index].codes} />
          <Bar dataKey={data.session_end_time} key={index+1} fill={this.favoriteColors[index+1].codes} />
        </React.Fragment>
      )   
  });
}

generateWeekView = () =>{
  const currentDate = new Date(); // get current date
  const first = currentDate.getDate() - currentDate.getDay();
  let fiveWeek= [];
  for (var i = 1; i < 7; i++) {
      let next = new Date(currentDate.getTime());
      next.setDate(first + i);
      let response = next.toString().split(' ');
      let responseDate = response[0]+' '+response[1]+' '+response[2];
      fiveWeek.push(responseDate)
  }
  this.setState({
    fiveWeekBar: fiveWeek
  });

}
componentDidMount() {
 
  this.generateWeekView();
 
}


  render(){

    const {classes} = this.props;
    debugger;
    let user_name = [], user_time_end = [],user_time_start = [];
    for(let b=  0 ; b<this.props.chartData.length; b++){
      user_name.push(this.props.chartData[b].user_identity);
      debugger;
      user_time_start.push(Number(new Date(this.props.chartData[b].entries.firstEntry.start_time)));
      user_time_end.push(this.props.chartData[b].entries.firstEntry.end_time !== null?Number(new Date(this.props.chartData[b].entries.firstEntry.end_time)):0);

    }
   const  ChangeDateToNumber =(item, index) => {
     debugger;
     let changeDateToNumber = item !== 0 ? Number(new Date(item)) : 0;

      return item !== 0 ? Number(new Date(item)) : 0;
      
    }
   // let test = user_time_start.forEach(ChangeDateToNumber);
    debugger;
   // let test1 = user_time_end.forEach(ChangeDateToNumber);



    const cardDetailsData = {
      labels: user_name,
      datasets: [
        {
          label: 'End time',
          backgroundColor: themeChartCard.primary.main,
          data: user_time_end
        },
        {
          label: 'Start time',
          backgroundColor: themeChartCard.neutral,
          data: user_time_start
        }
      ]
    };
    
    const cardDetailsOptions = {
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      legend: { display: false },
      cornerRadius: 20,
      tooltips: {
        enabled: true,
        mode: 'index',
        intersect: false,
        borderWidth: 1,
        borderColor: themeChartCard.divider,
        backgroundColor: themeChartCard.white,
        titleFontColor: themeChartCard.text.primary,
        bodyFontColor:themeChartCard.text.secondary,
        footerFontColor: themeChartCard.text.secondary
      },
      layout: { padding: 0 },
      scales: {
        xAxes: [
          {
            barThickness: 12,
            maxBarThickness: 10,
            barPercentage: 0.5,
            categoryPercentage: 0.5,
            ticks: {
              fontColor: themeChartCard.text.secondary
            },
            gridLines: {
              display: false,
              drawBorder: false
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              fontColor: themeChartCard.text.secondary,
              beginAtZero: true,
              min: 0
            },
            gridLines: {
              borderDash: [2],
              borderDashOffset: [2],
              color: themeChartCard.divider,
              drawBorder: false,
              zeroLineBorderDash: [2],
              zeroLineBorderDashOffset: [2],
              zeroLineColor: themeChartCard.divider
            }
          }
        ]
      }
    };
  

  return (
    <React.Fragment>
      <Title>Week</Title>
      <CardStatusBar
                  data={cardDetailsData}
                  options={cardDetailsOptions}
                />
              
    </React.Fragment>
  );
}
}
export default Chart;